import en from '@/locale/localeEn';
import { GM_CONTACT_EMAIL } from '@/global/constants';

export default {
  ...en,

  label_hi: 'Hi ',
  label_generalMeeting1: 'Junta General',
  label_generalMeetingName: 'Nombre de la junta general',
  label_issuer: 'Emisor',
  label_identificationNumber: 'Número de identificación',
  label_issuer_name: 'Nombre del emisor',
  label_type: 'Tipo',
  label_legal_entity: 'Persona jurídica ',
  label_natural_person: 'Persona física ',
  label_genMeetingDate: 'Fecha de la junta General',
  label_requestSubmittedAt: 'Solicitud presentada el',
  label_requestSubmittedBy: 'Solicitud presentada por',
  label_extendedIntermediaryDeadline: 'Plazo intermedio',
  label_new_end_date: 'Nueva fecha/hora de finalización',
  label_meetingDate: 'Fecha de la junta',
  label_status: 'Estado',
  label_action: 'Acción',
  label_advancedSearch: 'Búsqueda avanzada',
  label_searchForShareholder: 'Búsqueda de accionista',
  label_cet: ' CET',
  label_vc_group_success: 'Display name saved successfully for given group',
  label_securityClass: 'Clase de seguridad',
  label_createGenMeeting: 'Crear junta general',
  label_createDWTevent: 'Crear DWT event',
  label_editDWTevent: 'Edit DWT event',
  label_editGenMeeting: 'Editar junta general',
  label_downloadRegFormTemplate: 'Download registration form template',
  label_extReq: 'Extension request',
  label_vc_alias_name_error:
    'Please ensure that display name in English language is entered',
  label_approveExtReqError:
    'Please enter a valid date. Maximum 5 hours of extension time is allowed and Extension Date cannot be greater than General Meeting Date',
  label_cancelExtnReq: 'Extension Request has been cancelled successfully',
  label_approveExtnReq: 'Extension Request has been approved successfully',
  label_rejectExtensionReq: 'Extension Request has been rejected successfully',
  label_rejectAllExtnRequests:
    'Extension Requests have been rejected successfully',
  label_notAllowedExtnReq: 'Issuer does not allow (new) extension requests',
  label_userMaintenance: 'Mantenimiento del usuario',
  label_auditAndTemplate: 'Auditoría y plantilla',
  label_liveMeetingOverview: 'Live meetings overview',
  label_shareHolderEmail: 'Shareholder email',
  label_dwtEvents: 'DWT Events',
  label_entity: 'Entity',
  label_publicationDate: 'Fecha de publicación',
  label_registrationDeadline: 'Fecha límite de inscripción',
  label_closingDate: 'Fecha tope',
  label_intermediaryDeadline: 'Plazo Intermediario',
  label_options: 'Opciones',
  label_typeAndLocation: 'Tipo y ubicación',
  label_schedule: 'Horario',
  label_shareClasses: 'Tipos de acciones',
  label_agenda: 'Puntos del orden del día',
  label_agenda_number: 'Agenda number',
  label_features: 'Features',
  label_documents: 'Documentos',
  label_inviteSharePlan: 'Invite share plan',
  label_select: '--Seleccione--',
  label_meetingType: 'Tipo de junta',
  label_meetingName: ' Título de la junta',
  label_name: 'Nombre',
  label_corporateWebsite: 'Corporate website',
  label_website: 'Página web',
  label_location: 'Lugar',
  label_locationName: 'Nombre del lugar',
  label_locationOnTicket: 'Location on ticket',
  label_street: 'Dirección',
  label_postalCode: 'Código postal',
  label_country: 'País',
  label_proxiesList: 'Clearing custodian',
  label_selectClearingIntermediary: '--Select clearing intermediary--',
  label_timeZone: 'Time zone',
  label_ibanNumber: 'IBAN number',
  label_directDebit: 'Direct debit',
  label_convocationDate: 'Convocation date',
  label_shareholderAccessDate: 'Shareholder access date',
  label_intermediaryAccessDate: 'Intermediary access date',
  label_recordDate: 'Fecha de inscripción',
  label_shareholderDeadlineDate: 'Fecha límite de inscripción',
  label_intermediaryDeadlineDate: 'Intermediary deadline date',
  label_regListReleaseDate: 'Registration list release date',
  label_noOfSecurities: 'Número de acciones',
  label_generalMeetingDate: 'Fecha de la junta',
  label_error_hoursAndMinutes:
    'Please enter hours from 0 to 23 and minutes from 0 to 59',
  label_prefixNumber: 'Prefix number',
  label_isin: ' ISIN',
  label_shareClassName: 'Share class name',
  label_intermediary: 'Intermediario',
  label_shareholder: 'Shareholder',
  label_shareplanholder: 'Share plan holder',
  label_search: 'Search',
  label_additionalShareClass: 'Additional share class',
  label_additionalDocs: 'Additional documents',
  label_attachDocument: 'Attach document',
  label_browse: 'Hojas',
  label_votable: 'Votable',
  label_agendaItem: 'Elemento del orden del día',
  label_agent: 'Agente ',
  label_addNewVoteCollector: 'Add new vote collector',
  label_addVoteCollector: 'Add vote collector',
  label_noDataFound: 'No data Found',
  label_login: 'Iniciar sesión',
  label_loginHeader: 'iniciar sesión',
  label_forgotPwrd: 'Restablecer contraseña',
  label_gmNotice: 'GM notice',
  label_optOutGmNotice: 'No deseo recibir información sobre las JGA ',
  label_webaPage: 'Visible on relevant web pages',
  label_attendanceCard: 'Attendance card',
  label_securityClassName: 'Clase de los Valores',
  label_previous: ' Previous',
  label_next: 'Siguiente',
  label_prev: 'Anterior',
  label_first: 'Primero',
  label_last: 'Last',
  label_nl: 'NL',
  label_en: 'EN',
  label_fr: 'FR',
  label_es: 'ES',
  label_pipe: ' | ',
  label_colonStar: ':*',
  label_colon: ':',
  label_hash: '#',
  label_comma: ',',
  label_questionMark: '?',
  label_noDataMessage: 'No hay datos disponibles',
  label_noDataMessageExt: 'There are no extension requests available',
  label_agents: 'Agents',
  label_issuers: 'Issuers',
  label_approve: 'Approve',
  label_reject: 'Reject',
  label_rejectAll: 'Reject all',
  label_accept: 'Accept',
  label_confirmationMessage: 'Mensaje de confirmación',
  label_areYouSure: 'Are you sure you want to approve?',
  label_areYouSureSend: 'Are you sure you want to send?',
  label_areYouSureCancel: 'Are you sure you want to cancel?',
  label_areYouSureSave: 'Are you sure you want to save?',
  label_yes: 'Sí',
  label_no: 'No',
  label_save: 'Guardar',
  label_view: 'View',
  label_viewUsers: 'View users',
  label_edit: 'modificar',
  label_notification: 'Notification',
  label_notification_link: 'GM Notification Sheet',
  label_inviteShareplan: 'Invite share plan',
  label_add: 'Add ',
  label_city: 'Lugar',
  label_logo: 'Logotipo',
  label_addNew: 'Add new',
  label_admin: 'Admin',
  label_phoneNumber: 'Número de teléfono',
  label_personalPhoneNumber: 'Personal phone number',
  label_ok: 'Ok',
  label_groupMail: 'Opt-out for emails related to General Meetings',
  label_optOutTaxReclaim: 'Opt-out for emails related to Tax reclaim events',
  label_cancel: 'Cancelar',
  label_confirm: 'Confirmar',
  label_file: 'File',
  label_title: 'Encabezamiento',
  label_firstName: 'Nombre',
  label_lastName: 'Apellidos',
  label_meetingTypeAGM: 'AGM',
  label_meetingTypeEGM: 'EGM',
  label_goBack: 'anterior',
  label_home: 'Home',
  label_generalMeeting: 'Juntas Generales',
  label_DWT: 'DWT',
  label_shareholderID: 'Shareholder ID',
  label_corporateBroking: 'Corporate Broking',
  label_btnPending: 'En curso',
  label_btnAccepted: 'Aceptado',
  label_btnApproved: 'Aprobado',
  label_btnRejected: 'Rechazado',
  label_btnCancelled: 'Cancelado',
  label_requestCreatedAt: 'Solicitudes presentadas el',
  label_nameBeneficialOwner: 'Name beneficial owner',
  label_shareholderPosition: 'Shareholder position',
  label_option: 'Option',
  label_delete: 'Delete',
  label_generalDetails: 'Información general ',
  label_registrations: 'Registrations',
  label_voteCollector: 'Entidad que recopila las instrucciones de voto',
  label_locationOnCertificate:
    'Nombre del lugar en el certificado de inscripción',
  label_votingResult: 'Voting result',
  label_votingAvailable: 'Voting available',
  label_votingNotAvailable: 'Voting not available',
  label_votingItem: 'Voto',
  label_defaultVotingOptions: '(a favor, en contra, abstención)',
  label_intermediaryWorkstation: 'Intermediary workstation',
  label_shareholderWorkstation: 'Shareholder workstation',
  label_SplitVotingRequired: 'Split voting required',
  label_disclosureOfUnderlyingBeneficialOwnersRequired:
    'Disclosure of underlying beneficial owners required',
  label_fillText:
    '* Fill out all fields in this page and click "Save" button to navigate to next section.',
  label_generalMeetingShouldBeVisible: 'General meeting should be visible',
  label_enableNewVotingResolution: 'Enable new voting resolution',
  label_agentLogoOnRegistrationTicket: 'Agent logo on registration ticket',
  label_issuerLogoOnRegistrationTicket: 'Issuer logo on registration ticket',
  label_emailAddress: 'Dirección de correo electrónico',
  genDetails_label2: 'Disclosure of underlying beneficial owners required',
  label_shareClass: 'Tipos de acciones',
  label_new_securityClassName: 'Nueva Clase de los Valores',
  label_error_newSecurityClassNameReg:
    'El nombre clase de los Valores es obligatorio',
  label_error_newSecurityClassNameWrong:
    'El nombre clase de los Valores no es válido',
  label_securityClassDetails: 'Clase de los Valores detalles',
  label_companyName: 'Nombre de la sociedad',
  label_company: 'Sociedad',
  label_voteConfirmation: 'Confirmación de las instrucciones de voto',
  label_votingInstructions: 'Instrucciones de voto',
  label_viewVotingInstructions: 'ver instrucciones de voto',
  label_viewSharePlanHolder: 'View Share plan holder',
  label_viewattendanceRequests: 'View attendance requests',
  label_voteRightsPerShare: 'Número total de unidades con derecho a voto',
  label_shareplan: 'Share plan',
  label_votecollector: 'Entidad que recopila las instrucciones de voto',
  label_securities: 'securities',
  label_resend_selected: 'Resend Selected',
  label_instruction: 'instruction',
  label_attendanceRegistration: 'Attendance registration',
  label_votingInstruction: 'Voting instruction',
  label_oldPwrd: 'Contraseña actual',
  label_newPwrd: 'Nueva contraseña',
  label_reenterPwrd: 'Confirme nueva contraseña',
  searchSharePlanHolder_label: 'Securities holder name/Email',
  label_resend: 'Resend',
  label_resendInviteSharePlan: 'Resend invite share plan',
  label_no_sharePlan_holders_available: 'No Share plan holders available',
  label_sharePlan_reinvite:
    'Invite share plan email will be sent to the selected share plan holders',
  label_changePwrd: 'Cambiar contraseña',
  label_submit: 'Submit',
  label_pwrdRequirements: 'Requisitos de contraseña',
  label_total: 'Total',
  label_download: 'Download',
  label_registrationListPDF: 'Registration list PDF',
  label_registrationListXLS: 'Registration list XLS',
  label_shareholdersAttendanceRegistration:
    'Shareholders requested for attendance registration',
  label_shareholdersVotingInstructions:
    'Shareholders requested for voting instructions',
  label_reinviteShareplan: 'View/Reinvite share plan holder',
  'label_%ofOutstandingVotingRights': '% of outstanding voting rights',
  label_attendanceRequest: 'Inscribirse',
  label_noAction: 'No action',
  label_registrationTicketPreview: 'Registration ticket preview',
  label_downloadRegCertificate: 'Download all registration certificates',
  label_registrationCertificates: 'Registration certificates',
  label_createNewRegistration: 'Create new registration',
  label_downloadAll: 'Download all',
  label_uppercaseLetters: 'al menos 1 mayúscula',
  label_lowercaseLetters: 'al menos 1 minúscula',
  label_numbers: 'al menos 1 número',
  label_specialCharacters: `al menos 1 carácter especial ($ + , : ; = ? @ # | ' . ¡^ * ) ( % ! - ~ } { ] [ _ / )`,
  label_8ormoreCharacters: 'mínimo de 8 y máximo de 20 caracteres',
  label_email: 'Dirección de correo electrónico',
  label_addOtherLang: 'Add other language',
  label_important: 'Important',
  label_registrationTicketCreated: 'Registration ticket created',
  label_emailAuditTrail: 'Email Audit Trail',
  label_auditTrail: 'Audit Trail',
  label_templates: 'Templates',
  label_uploadTemplates: 'Upload templates',
  label_sentOutMails: 'Sent out mails',
  label_reset: 'Hacer público',
  label_securitiesHolder: 'Securities holder',
  label_registrationNumber: 'Registratie nummer',
  label_proxyName: 'Nombre del apoderado',
  label_noOfShares: 'Número de acciones',
  label_fromDate: 'From date',
  label_toDate: 'To date',
  label_intermediaries: 'Intermediaries',
  label_emailType: 'Email type',
  label_receiverEmailAddress: 'Receiver email address',
  label_subject: 'Subject',
  label_emailDetails: 'Email details',
  label_sentTo: 'Sent to',
  label_userName: 'User name',
  label_from: 'From',
  label_to: 'To',
  label_auditTrailDetails: 'Audit Trail Details',
  label_user: 'User',
  label_platform: 'Platform',
  label_Date: 'Date',
  label_templateType: 'Template type',
  label_subbjectOrDescription: 'Subject/Description',
  label_descriptionDetails: 'Description details',
  label_template: 'Template',
  label_language: 'Language',
  label_modified: 'Modified',
  label_sender: 'Sender',
  label_documentType: 'Document type',
  label_dwtBulkUpload: 'DWT bulk upload',
  label_bulkUpload: 'Bulk upload',
  label_bulkUploadVI: 'Bulk upload voting instructions',
  label_users: 'Users',
  label_role: 'Role',
  label_event: 'Event',
  label_eventName: 'Event name',
  label_dwtEvent: 'DWT envent',
  label_votingInvitationWithLinkToMeetings:
    'Voting invitation with link to meetings',
  label_sentDate: 'Sent date',
  label_newIntermediary: 'New Intermediary',
  label_intermediaryName: 'Nombre del intermediario',
  label_intermediaryPhone: 'Teléfono del intermediario',
  label_intermediaryEmail: 'Intermediary email address',
  label_intermediaryContactName: 'Persona de contacto intermediario',
  label_intermediaryGroupName: 'Intermediary group name',
  label_bic: 'BIC',
  label_registrationForm: 'Formulario de inscripción',
  label_processVotingInstructions: 'Process voting instructions',
  label_receiveMeetingNotification: 'Receive meeting notification via Swift',
  label_processRegRequest: 'Process registration request',
  label_visibleInShareholderWorkstation: 'Visible in Shareholder workstation',
  label_CanProcessOnlyAssignedShareclasses:
    'Can process only assigned share classes',
  label_processCustomTemplate:
    'Process custom template for RC email to shareholder',
  label_addIssuer: 'Add Issuer',
  label_permissionsForms: 'Permissions / Registration forms',
  label_validFrom: 'Valid from',
  label_validTo: 'Valid to',
  label_openBracket: '(',
  label_closeBracket: ')',
  label_shareholderType: 'Shareholder type',
  label_selectShareholderType: '--Select Shareholder type--',
  label_initials: 'Iniciales',
  label_isAdmin: 'Es admin',
  label_searchResults: 'Search results for',
  label_resetInstructionsSent: 'Reset instruction sent',
  label_resetPwrd: 'Reset password',
  label_checkEmail: 'Check your email',
  label_canProceedVotingInstructions: 'Can process voting instructons',
  label_addIntermediary: 'Añada intermediario',
  label_addMoreIntermediary: 'Añada más intermediario',
  label_addNewProxy: 'Add new proxy',
  label_thankYou: 'Gracias',
  forgotPassSuccess_label1:
    'Se ha ejecutado la solicitud de restablecimiento de contraseña. Si el nombre de usuario especificado existe, se le enviará un correo electrónico con instrucciones.',
  label_entityName: 'Entity name',
  label_actionType: 'Action type',
  label_sentDateGMT: 'Sent date and time',
  label_screenName: 'Screen name',
  label_templateName: 'Template name',
  label_workstation: 'Workstation',
  label_templateText: 'Template text',
  label_doNotPublish: 'Do not publish',
  genMeetRegistration_label1: 'Total number of outstanding voting rights',
  genDetails_label1:
    'Include meeting documents along with registration certificate',
  agent_home_welcomeText1: `Welcome to ABN AMRO's Corporate Broking Portal, where you can facilitate your general meetings and DWT processes.`,
  agent_home_welcomeText2: 'Please find below your ',
  votingByCorrespondence: 'Voto por correspondencia',
  virtualParticipation: 'Participación virtual',
  label_actionList: 'action list.',
  createGenMeet_fillFormText: 'Fill form in',
  label_euroclear_shares: 'Euroclear shares',
  createGenMeet_draftSaveText: 'Draft automatically saved at',
  createGenMeet_shareClass_label1:
    'Total number of outstanding securities, entitled to vote',
  createGenMeet_shareClass_label10:
    'Total number of outstanding securities for Euroclear Nederland, entitled to vote',
  createGenMeet_shareClass_label2:
    'Vote rights per share(for this share class)',
  createGenMeet_shareClass_label3: 'Total number of voting rights',
  createGenMeet_shareClass_maxARAllowed:
    'Número máximo de solicitudes de asistencia permitidas',
  createGenMeet_vote_by_proxy: 'Votar a través de proxy',
  createGenMeet_vote_by_correspondence: 'Votar a correspondencia',
  createGenMeet_shareClass_label5: 'Solicitud de presencia',
  createGenMeet_shareClass_label5_1: 'Participación virtual',
  createGenMeet_shareClass_label6: 'Registration list available via',
  createGenMeet_shareClass_label7: 'Vote collector workstation',
  createGenMeet_shareClass_label8: 'Authorized vote collectors',
  createGenMeet_shareClass_label9: 'Entidad Emisora',
  createGenMeet_shareClass_agent_itself: 'Agente',
  createGenMeet_agenda_label1: 'Explanation (if applicable)',
  createGenMeet_agenda_label2: 'New venture approval',
  createGenMeet_agenda_label3: 'Add agenda item',
  createGenMeet_agenda_copy: 'Copy agenda items',
  createGenMeet_agenda_copy_tooltip:
    'Copies all agenda items to any other selected language',
  createGenMeet_doc_label1: 'Attach file to',
  createGenMeet_feature_label1: 'Intermediary workstations',
  createGenMeet_feature_label2: 'Split voting allowed',
  createGenMeet_feature_rightToSpeak:
    'Attendance request with right to speak allowed',
  createGenMeet_feature_label3: 'Shareholder workstations',
  createGenMeet_feature_label5: 'Split voting allowed',
  createGenMeet_feature_label6: 'Securities account number disclosure required',
  linkedSC_feature_tooltip: `Enabling this feature will alert shareholders to vote/attend for the meeting which has shared share class(ISIN). The message will be displayed in shareholder work station only if the current date is with in the range of other meeting's shareholder access and deadline dates`,
  linkedMeeting_attend_label: '¿Le gustaría asistir a la junta de',
  linkedMeeting_vote_label: '¿Desea votar en la junta de',
  label_plsClick_Yes_toContinue: 'Entonces haga clic en "Sí" para continuar',
  createGenMeet_feature_linkedSC:
    'Display message to vote/attend for the share class linked meeting',
  label_registrationTicket: 'Registration ticket',
  createGenMeet_feature_label8: 'Include barcode on registration ticket',
  createGenMeet_feature_label9: 'Agent logo on attendance card',
  createGenMeet_feature_label10: 'Issuer logo on attendance card',
  createGenMeet_feature_label11:
    'Include blank power of attorney on registration ticket',
  createGenMeet_feature_label12:
    'Include meeting document along with registration certificate',
  virtualParticipation_allowed: 'Virtual participation allowed',
  only_9numbers_error: 'Please enter numeric value of length 9 characters only',
  virtual_participation_provider_meeting_id: 'Meeting ID',
  virtual_participation_provider_info1:
    '- It should be 9 numerics without any special characters. example: 127909002.',
  virtual_participation_provider_info2:
    '- Please share the meeting id and the issuer details with the virtual participation provider once the meeting is successfully created.',
  createGenMeet_InviteSP_label1: 'From address',
  label_toAddress: 'To address',
  createGenMeet_InviteSP_label2: 'Email subject',
  createGenMeet_InviteSP_label3: 'Email body',
  createGenMeet_InviteSP_label4: ' Include uploaded files',
  createGenMeet_InviteSP_label5: 'No Files uploaded',
  createGenMeet_InviteSP_label6: 'Please confirm your registration',
  createGenMeet_InviteSP_label7: 'Step 1 ',
  createGenMeet_InviteSP_label8: 'Step 2 ',
  createGenMeet_InviteSP_download: 'Download Share Plan template',
  createGenMeet_InviteSP_upload: 'Upload Share Plan data',
  createGenMeet_InviteSP_placeholder1: 'corporate.broking.finance_dummy@nl.com',
  createGenMeet_InviteSP_placeholder2: 'Please confirm your registration',
  createGenMeet_InviteSP_tooltip1:
    'Template needs to be filled in with share plan data by issuer.',
  createGenMeet_InviteSP_tooltip2:
    'Template downloaded earlier needs to be uploaded with share plan data.',
  userMaint_voteCollector_label:
    'Entidad que recopila las instrucciones de voto',
  userMaint_intermediary_label: 'Intermediario',
  userMaint_proxy_label: 'Proxy Intermediary',
  userMaint_groupDetails_label: 'Group details',
  userMaint_userAccounts_label: 'User accounts',
  userMaint_groupEmailAddress_label:
    'Dirección de correo electrónico del grupo',
  userMaint_bankIdentifierCode_label: 'Bank Identifier Code',
  userMaint_bankAccountNumber_label: 'Bank account number',
  userMaint_usersList_label: 'Users list',
  userMaint_officePhone_label: 'Office phone',
  userMaint_commentsForRejection_label: 'Comments for rejection',
  userMaint_issuer_warning_alreadyvc_label:
    'The provided e-mail is already registered as Vote Collector. Are you sure you want to add it as Issuer?',
  addUser_userAccounts_label: 'User account',
  forgotPass_label1: 'Solicitud de nueva contraseña enviada',
  forgotPass_label2:
    'Have you lost your password? You can request a new password. We will e-mail an activation link, and you can then enter a new password.',
  viewAttendance_label1:
    'Share holder name/Registration number/Securities account number',
  viewAttendance_label2: 'Shareholders registered list',
  viewVotingInstructions_label1: 'Securities holders registered list',
  item0: 'Home',
  item1: 'Juntas Generales',
  item3: 'DWT',
  item4: 'Shareholder ID',
  item5: 'Corporate Broking',
  item2: 'Mis datos',
  button0: 'Draft',
  button1: 'Pending',
  button2: 'To be published',
  button3: 'Live',
  button4: 'Completed',
  button5: 'Cancelado',
  extnbtn0: 'Pending',
  extnbtn1: 'Accepted',
  extnbtn2: 'Rejected',
  label_votingConfirmation: 'Voting confirmation',
  label_votingInstructionReport: 'Voting instruction reports',
  label_downloadPDF: 'Download PDF',
  label_downloadExcel: 'Download Excel',
  label_summary: 'Summary',
  label_myVotes: 'Mis inscripciones/votos emitidos',
  label_Details: 'Detalles',
  label_inFavor: 'In favor',
  label_against: 'En contra',
  label_Abstained: 'Abstenerse',
  label_addAttachments: 'Add attachments',
  label_addText: 'Add text',
  label_send: 'Enviar',
  adminAgent_label_profile: 'Perfil',
  adminAgent_label_changePwrd: 'Cambiar contraseña',
  adminAgent_label_initials: 'Iniciales',
  adminAgent_label_middleName: 'Nombres',
  adminAgent_label_phoneNumber: 'Número de teléfono',
  adminAgent_label_pwrdRequirements: 'Requisitos de contraseña',
  adminAgent_label_confirmation: 'Confirmation message',
  adminAgent_label_wantToSave: 'Are you sure you want to save?',
  gmNotification_label_1: 'Notification overview',
  gmNotification_label_2: 'Attachments to notification',
  gmNotification_label_3: 'Customise message',
  gmNotification_label_4: 'Meeting notice',
  gmNotification_label_5: 'Recipient list',
  label_recipientAddress: 'Recipient address',
  gmNotification_custMsg1: 'Available means of participation',
  gmNotification_custMsg2:
    'Additional information for notification of participation',
  gmNotification_custMsg3: 'Narrative',
  gmNotification_custMsg4:
    'Other requirements for participation (if applicable)',
  voting_result: 'Voting results',
  select_recipient: '--Select recipient--',
  select_shareClass: '--Seleccione el tipo de acciones--',
  label_selectProxyType: '--Seleccione el tipo de apoderado--',
  label_selectCountry: '--Seleccione el país--',
  label_selectUserType: '--Seleccione el tipo de usuario--',
  label_selectTitle: '--Seleccione el encabezamiento--',
  label_selectIntermediary: '--Seleccione el intermediario--',
  gmGeneralDetails_label: 'Shareholder',
  label_serialNo: 'Sl No.',
  label_error_lastNameReq: 'Last name is required.',
  label_error_lastNameWrong: 'El nombre no es correcto',
  label_error_officeNumReq: 'Office phone number is required.',
  label_error_officeNumWrong: 'El teléfono de la oficina es incorrecto',
  label_error_phoneNumReq: 'Phone number is required',
  label_error_persoNumWrong: 'Personal phone number is wrong',
  label_error_phoneNumWrong:
    'El teléfono es demasiado corto o el formato no es válido',
  label_error_emailReq: 'La dirección de correo electrónico es obligatoria',
  label_error_emailWrong:
    'La dirección de correo electrónico está incompleta o no se ajusta al formato',
  label_error_name: 'El nombre es obligatorio',
  label_error_nameWrong: 'El nombre no es correcto',
  label_error_postWrong: 'El código postal no es correcto',
  label_error_cityReq: 'El lugar es obligatorio',
  label_error_cityWrong: 'El lugar no es correcto',
  label_error_noOfSharesReq: 'El número de acciones es obligatorio',
  label_error_noOfSharesWrong: 'El número de acciones no es correcto',
  label_error_selectedQuantityNoOfSharesReq:
    'La participación parcial es obligatoria',
  label_error_selectedQuantityNoOfSharesWrong:
    'La participación parcial es incorrecta',
  label_error_addressWrong: 'La dirección no es correcta',
  label_error_securityAccNoReq: 'El número de cuenta de valores es obligatorio',
  label_error_securityAccNoWrong:
    'Al introducir un número de cuenta de inversión/valores, solo podrá utilizar números y letras',
  label_error_countryReq: 'Country is required.',
  label_error_countryWrong: 'Country is wrong.',
  label_error_bicReq: 'Bank Identifier Code is required.',
  label_error_bicWrong: 'Bank Identifier Code is niet juist',
  label_error_legalIndentifierWrong: 'Legal entity identifier is wrong.',
  label_error_repNameWrong: 'Representative name is wrong.',
  label_error_proxyNameWrong: 'Proxy name is wrong.',
  label_error_proxyNameReq: 'El nombre del apoderado es obligatorio',
  label_poaForm: 'Formulario de poder',
  linkIntermediary_header1:
    'Intermediaries for Institutional Shareholder Services (ISS) Europe',
  linkIntermediary_header2:
    'These are the intermediaries for which Institutional Shareholder Services (ISS) Europe can process regulations',
  linkIntermediary_button1: 'Link to Intermediary group',
  addPermission_header: 'Permissions/Registration form',
  label_error_middleNameWrong: 'Middle name is wrong.',
  label_error_firstNameWrong: 'First name is wrong.',
  label_error_initialsWrong: 'Las iniciales son incorrectas',
  label_error_titleWrong: 'Title is wrong.',
  label_error_titleReq: 'Title is required.',
  voteCollectorbutton0: 'Before deadline',
  voteCollectorbutton1: 'After deadline',
  voteCollectorbutton2: 'Completed',
  label_userType: 'User type',
  label_address: 'Dirección',
  label_dob: 'Fecha de nacimiento',
  label_securityAccountNumber: 'Cuenta de inversión',
  label_numberOfShares: 'Número de acciones',
  label_attendByProxy: 'Otorgar poder',
  label_createNewProxy: 'Nuevo apoderado',
  label_editProxy: 'Modificar apoderado',
  label_selectProxy: 'Seleccione apoderado',
  label_delegateToSaat: 'Delegar a SAAT',
  label_attendOrBlankVoteLine1: 'Asistir a la junta',
  label_attendOrBlankVoteLine2: 'o dar una instrucción en blanco a la SAAT',
  label_proxyType: 'Tipo de apoderado',
  label_proxyId: 'Id de apoderado',
  label_none: 'No',
  label_vote: 'Mediante instrucciones de voto',
  label_register: 'Registrar nuevo usuario',
  label_allFor: 'Todo a favor',
  label_splitVotes: 'Split votes',
  label_entireHolding: 'Participación total',
  label_selectedQuantity: 'Selected quantity',
  label_partialHolding: 'Participación parcial',
  label_for: 'A favor',
  label_enterNumberOfShares: 'Número de acciones',
  label_allAgainst: 'Todo en contra',
  label_allAbstained: 'Abstenerse',
  label_attend: 'Asistir a la junta',
  label_downloadCertificate: 'Download certificate',
  label_upComingMeetings: 'Próximas Juntas Generales',
  label_representativeName: 'Nombre del representante',
  label_legalEntityIdentifier: 'Identificador de entidad jurídica',
  label_termsAndConditions: 'Condiciones generales',
  label_terms: 'He leído y acepto las condiciones generales',
  label_termsUse: 'Condiciones de uso',
  label_numberOfSecurities: 'Number of securities',
  label_attendanceInfo:
    'The following registrations will be automatically cancelled when attendance request is submitted',
  label_votingInfo:
    'The following registrations will be automatically cancelled when voting instruction is submitted',
  label_termsSentence: 'I have read and accepted the ',
  label_belgiumTermsConditions1: 'Declaro/declaramos que he/hemos leído las ',
  label_belgiumTermsConditions2:
    ', que entiendo/entendemos y acepto/aceptamos su contenido y que he/hemos guardado, impreso o enviado una copia de las mismas a mí/nosotros mismo(s).',
  label_votingViaIMWorkStation:
    'Voting instructions via the intermediary workstation',
  label_votingViaSHWorkStation:
    'Voting instructions via the shareholder workstation',
  label_requestRegistrationViaSHWorkStation:
    'Request a registration ticket via the shareholder workstation',
  label_groupName: 'Group name',
  label_groupCountry: 'Group country',
  label_groupRegisteredOffice: 'Group registered office',
  label_internationalBankAccountNumber: 'International bank account number',
  label_taxDeclarationNumber: 'Tax declaration number',
  label_uploadedDocuments: 'Uploaded documents',
  label_groupStatus: 'Group status',
  label_groupCity: 'Group city',
  notification_text:
    'Estimado accionista, para ofrecerle el mejor servicio posible, le rogamos que compruebe sus datos.',
  label_companiesOfferingGm:
    'Las sociedades que utilizan el portal del accionista de ABN AMRO son:',
  label_seeMore: 'Más información',
  label_knowMore: 'Know more',
  label_error_groupName: 'Group name is wrong',
  label_error_groupNameReq: 'Group name is required',
  label_pleaseFillThisField: 'Please fill this field',
  label_proxyProvided: 'Proxy provided',
  label_proxyProfile: 'Proxy profile',
  label_noDataIssuerMessage: 'No data available for issuer',
  label_feedback: 'Feedback',
  label_extensionRequestFor: 'Extension Request for',
  label_shareHolderName: 'Shareholder name',
  label_extendDeadlineTillDate: 'Extend deadline till date',
  label_reasonForDelay: 'Reason for delay',
  label_reasonForReject: 'Reason for rejection',
  label_time: 'Time',
  label_createExtnReq: 'Create extension request',
  label_tillDateReq: 'Extend deadline till date is required',
  label_shareholderNameReq: 'Shareholder name is required',
  label_shareholderPosReq: 'Shareholder position is required',
  label_shareholderPosError: 'Shareholder position is wrong',
  label_shareholderPosIncorrect: 'Shareholder position can be 1 to 999999999',
  label_attachment: 'Attachment',
  label_importFile: 'Import file',
  label_downloadStandardFile: 'Download standard file',
  label_bulkUploadAttendanceRequest: 'Bulk upload attendance request',
  label_MR: 'Mr.',
  label_MS: 'Ms.',
  label_voting_instruction: 'Instrucciones de voto',
  label_attendance_requests: 'Solicitud de inscripción',
  label_invite_share_plan: 'Invite share plan',
  label_shareholder_email: 'Shareholder email',
  label_intermediary_email: 'Intermediary email',
  label_notification_overview: 'Notification Overview',
  label_create_extension_request: 'Create Extension Request',
  label_bulk_upload_attendance_requests: 'Bulk upload attendance request',
  label_bulk_upload_voting_instructions: 'Bulk upload voting instructions',
  label_view_attendance_requests: 'View attendance requests',
  label_view_agent_group: 'View agent group',
  label_edit_agent_group: 'Edit agent group',
  label_edit_issuer_details: 'Edit issuer details',
  label_edit_intermediary_details: 'Edit intermediary details',
  label_edit_proxy_intermediary_details: 'Edit proxy intermediary details',
  label_edit_votecollector_details: 'Edit vote collector details',
  label_agent_group: 'Agent users',
  label_view_agent_group_users: 'View agent users',
  label_add_user: 'Add user',
  label_add_issuer: 'Add issuer',
  label_add_intermediary: 'Añada intermediario',
  label_add_proxy_user: 'Add proxy user',
  label_add_vote_collector: 'Add vote collector',
  label_view_agent_details: 'View agent details',
  label_edit_agent_details: 'Edit user details',
  label_edit_vote_collector_details: 'Edit vote collector details',
  label_view_issuer_group: 'Issuer groups',
  label_view_vote_collector_group: 'Vote collector groups',
  label_view_intermediary_group: 'Intermediary groups',
  label_view_proxy_intermediary_group: 'Proxy intermediary groups',
  label_add_issuer_group: 'Add issuer group',
  label_add_vote_collector_group: 'Add vote collector group',
  label_add_intermediary_group: 'Add intermediary group',
  label_add_proxy_intermediary_group: 'Add proxy intermediary group',
  label_view_intermediary_user: 'View intermediary users',
  label_view_proxy_intermediary_user: 'View proxy intermediary users',
  label_view_issuer_user: 'View issuer users',
  label_view_vote_collector_user: 'View vote collector users',
  label_view_issuer_details: 'View issuer details',
  label_view_intermediary_details: 'View intermediary details',
  label_view_proxy_intermediary_details: 'View proxy intermediary details',
  label_view_vote_collector_details: 'View vote collector details',
  label_add_permissions: 'Add permissions',
  label_link_intermediaries: 'Linked intermediaries',
  label_link_intermediary: 'Link to Intermediary',
  label_linkedIntMessage:
    'If needed intermediary is not listed, please contact Agent to onboard in the system.',
  label_linkedIntError:
    'The PoA for the selected intermediary has expired or is not yet valid, please contact Agent.',
  label_page_title: 'Corporate Broking Portal',
  label_logoUpdate: 'Update logo',
  label_extededTill: 'Intermediary deadline extended till',
  label_reasonForExtension: 'Reason for extension',
  label_privacy_policy: 'Política de privacidad y cookies',
  label_privacy: 'Privacidad',
  label_cookies: 'Cookies',
  label_contact_details: 'Datos de contacto',
  label_numberOfSharesRequired: 'El número de acciones es obligatorio',
  label_numberOfSharesWrong:
    'El número de acciones debe presentarse en números y no debe empezar por cero',
  label_addProxy: '+ Add Proxy',
  label_mandatoryFieldError: 'Cumplimente los campos obligatorios',
  label_textRequired: 'Text is required',
  label_pwrdReq: 'Introduzca la contraseña',
  newPwd_oldPwdError:
    'La nueva contraseña no debe ser la misma que la anterior',
  checkPattern_Error: 'Compruebe el formato',
  label_noProxyAvailable: 'No proxy available',
  label_close: 'Cerrar',
  label_feedbackType: 'Tipo de comentarios:',
  label_feedbackComment: 'Comentarios',
  label_feedback_question: 'Pregunta',
  label_feedback_opinion: 'Comentario',
  label_feedback_comment_required: 'Feedback comment is required.',
  label_feedback_comment_maxlength: 'Max length is 4000 characters.',
  label_reason_comment_required: 'Reason comment is required.',
  label_reason_comment_maxlength: 'Max length is 2000 characters.',
  label_phone: 'Teléfono',
  label_show_details: 'Mostrar detalles',
  label_hide_details: 'Ocultar detalles',
  thankYou_msg: 'Your vote is important.Thank you for your voting instruction.',
  thankYou_msg1:
    'If you wish to change your vote, you can do this upto shareholder deadline date of the general meeting.',
  thankYou_msgButton1: 'Vote for other Share classes for this GM',
  thankYou_msgButton2: 'Browse and vote for GMs from other Issuers',
  notificationModal_header: 'Save Customised Message',
  notificationModal_body:
    'Do you want to retain the modified text for future use? (applies to all Issuers)',
  notificationModal_rightButton: 'No,for this instance only',
  notification_successMsg1: 'Message saved successfully for future use',
  notification_successMsg2: 'Message saved successfully for only this instance',
  notification_successMsg3: 'Notification sent successfully',
  label_shareHolderCreated:
    'Su inscripción se ha completado. Compruebe su correo electrónico para activar la cuenta.',
  label_shareHolderCreated_header: 'Inscripción completada',
  label_shareholderCreationError:
    'Some error has occured while creating Shareholder, Please contact system administration',
  label_agendaDeleted: 'Agenda deleted successfully.',
  label_agendaItemsCopied:
    'Agenda items successfully copied to other language(s).',
  label_documentDeleted: 'Document deleted successfully.',
  label_shareClassDeleted: 'Share class deleted successfully.',
  label_messageSaved: 'Meeting saved successfully.',
  label_createMeetingError:
    'Something went wrong while creating meeting. Please try after sometime',
  label_fillMandatoryFields:
    'Please fill mandatory fields to save this section',
  label_fillValidInput:
    'Please fill mandatory fields with valid input and try again.',
  label_attachmentRequired:
    'Attachment cannot be blank, Please upload a pdf file',
  label_documentLessThan5mb: 'Document should be less than 5mb',
  label_meetingSentToIssuer: 'Meeting sent to Issuer',
  label_meetingApproved: 'Meeting approved successfully',
  label_agendaRequired: 'Please fill agenda section',
  label_docRequired: 'Please fill document section.',
  label_shareClassRequired: 'Please fill share class section.',
  label_create_extn_error:
    'Extend deadline till date must be greater than intermediary deadline date and less tha general meeting date.',
  label_modalConfirmAction: 'Confirm action',
  label_modalVotingInsCreatErr: 'Voting is not available',
  label_modalVotingInsCreatErrMsg:
    'Voting instruction cannot be created for this General Meeting as the intermediary deadline date is already crossed',
  label_modalBulkUploadCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as voting instruction creation is not enabled',
  label_modalBulkUploadAttendCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as attendance request creation is not enabled',
  label_modalShareVotingInsCreatErrMsg:
    'No es posible enviar instrucciones de voto para esta junta',
  label_modalShareAttenReqCreatErrMsg:
    'No es posible inscribirse en esta junta',
  label_modalAttReqCreatErr: 'No es posible inscribirse',
  label_modalAttReqCreatErrMsg:
    'You cannot create attendance request for this General Meeting as the intermediary deadline date is already crossed',
  label_modalVirtualReqCreateErr: 'Cannot create virtual participation',
  label_modalVirtualReqNoEntitlements:
    'Not entitlements found for this meeting',
  label_modalVirtualReqErrMsg:
    'You cannot update virtual participation request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCreatErrMsg:
    'You cannot create/edit right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCancelErrMsg:
    'You cannot cancel right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalDelete: 'Delete message',
  label_modalConfirmDelTemplate:
    'Are you sure you want to delete this template?',
  label_modalUpdateTemp: 'Update template',
  label_modalConfirmUpdateTemplate:
    'Are you sure you want to update this template?',
  label_shareHolderRegistration: 'Inscripción de accionistas',
  label_registrationCertificate: 'Certificado de inscripción',
  label_request_type: 'Tipo de solicitud',
  lable_newReenterError: 'La contraseña no es igual',
  issuer_groupUpdated_success: 'Issuer group details updated successfully',
  label_votingInsCreatErr: 'Información',
  label_bulkUploadCreatErr: 'Upload error',
  error_shareClass_not_created:
    'Something went wrong while saving share class. Please try after sometime.',
  error_shareClass_not_created_invalid_data:
    'Please fill mandatory fields with valid information',
  only_pdf_allowed: 'Invalid document(upload valid pdf file).',
  agenda_file_sizeMsg: 'This agenda file size is ',
  file_sizeMsg: ' MB. Please upload a file less than 5 MB.',
  document_file_sizeMsg: 'This document size is ',
  error_send_to_issuer:
    'There are errors. Please verify if all the sections are filled with valid information',
  newPwd_requiredError: 'New password required',
  reEnter_requiredError: 'Re-enter password required',
  numAlpha_error: 'Only alphabets and numbers are allowed',
  password_renterMatchError:
    'Both New password and Re enter password must be same',
  label_bulkUploadBO: 'Upload BO Details',
  label_registration_no: 'Número de inscripción',
  label__error_registrationNoWrong: 'Registration Number is wrong',
  label__error_registrationNoReq: 'Registration Number is required',
  deadLineVoteModal_header: 'Voting is not available',
  deadLineModal_body:
    'Ya no es posible inscribirse en esta junta porque el plazo ha vencido.',
  deadLineModalVI_body:
    'Ya no puede enviar instrucciones de voto para esta junta porque el plazo ha vencido.',
  deadLineAttendModal_header: 'Attendance request not available',
  lable_nonVotableItem: 'Non-votable item',
  share_class_error_checkbox_not_selected:
    'At least one option among Voting instructions or Request an attendance card must be selected.',
  document_checkbox_not_selected:
    'Please check atleast one checkbox - Attach the file to',
  noOfShares_error1:
    'Split votes should not be greater than Total Number of Shares',
  noOfShares_error2:
    'Sum of Split votes should be equal to Total Number of Shares',
  noOfShares_error3:
    'Partial quantity cannot be greater than Total No of shares',
  votingInstruction_error_msg:
    'Para todos los votos de los puntos del orden del día',
  'intermediary-success-msg': 'Intermediary request created successfully',
  reject_meetingErrorr:
    'Something went wrong while rejecting meeting. Please try after sometime',
  approve_meetingErrorr:
    'Something went wrong while approving meeting. Please try after sometime',
  reject_meeting: 'Meeting rejected successfully.',
  approve_meeting: 'Meeting approved successfully.',
  label_loginOrRegister: 'Login or Register',
  label_downloadAttachment: 'Download attachment',
  label_selectActionType: '--Select action type--',
  label_fields: 'Fields',
  label_beforeValue: 'Before value',
  label_afterValue: 'After value',
  label_forWord: 'A favor',
  label_selectTemplateType: '--Select template type--',
  label_selectTemplateName: '--Select template Name--',
  label_selectWorkstation: '--Select Workstation--',
  label_selectIssuer: '--Select issuer--',
  label_selectScreenName: '--Select screen name--',
  label_saveSuccessfullyStatus: 'Invite share plan sent successfully',
  label_errorImgFile: 'Please upload an image file.',
  label_errorFileSize: 'Please upload a file less than 250 KB.',
  pwdChanged_successMsg: 'Password changed successfully',
  extnReq_cannotCreate: 'Extension request cannot be created for this meeting',
  mailSent_successMsg: 'Mail(s) sent successfully',
  selectDate_error: 'Please select date fields',
  fileSize_1mbError: 'Please upload a pdf file (Max Size 1 MB)',
  selectToDate_error: 'Please select To date which is greater than From date',
  selectRegForm_error: 'Please select Registration Form',
  label_areYouSureModal: 'Are you sure you want to ',
  label_thisRecord: ' this record?',
  label_shareHolder_registered: 'Shareholders registered by ',
  label_for_shareholder: 'for',
  label_toggle: 'Toggle navigation',
  uploadFile_error: 'Please upload a file.',
  fileSizeIs_error: 'This file size is: ',
  confrim_delink: 'Confirm de-link.',
  delink_confirmMsg: 'Are you sure you want to de-link this intermediary?',
  label_thisGroup: ' this group?',
  label_thisUser: ' this user?',
  label_enterUserNameToPasswordReset:
    'Introduzca su dirección de correo electrónico para solicitar una nueva contraseña.',
  label_enterValidCredentials:
    'Combinación desconocida de nombre de usuario y contraseña',
  label_emailEmptyError: 'Please fill in email body',
  label_acceptExtnReqConfirm:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReqConfirm:
    'This request was approved before, are you sure you want to reject it?',
  label_acceptExtnReq:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReq:
    'This request was approved before, are you sure you want to reject it?',
  label_viewEditExtnReq: 'View/Edit Extension request',
  label_section_error: 'There are errors for ',
  label_section_language: 'language(s)',
  areSureDelete_modal: 'Are you sure you want to delete this Meeting?',
  areSureCancel_modal: 'Are you sure you want to cancel this Meeting?',
  areSureReject_modal: 'Are you sure you want to reject this Meeting?',
  createGenMeet_IntermediaryTooltip:
    'Select an intermediary,ADR or transfer agent which is only responsible to process voting / registration to attend instructions for this share class. Please select all intermediaries if this is not applicable.',
  issuer_name_req: 'Issuer name is required.',
  fill_issuer: 'Please fill out Issuer name.',
  meeting_type_req: 'Meeting type is required.',
  fill_meetingType: 'Please fill out meeting type.',
  meeting_name_req: 'Meeting name is required.',
  invalid_meetingName: 'Meeting name field accepts alphabets and numbers.',
  fill_meetingName: 'Please fill out meeting name.',
  website_req: 'Corporate website is required.',
  website_wrong: 'Corporate website is wrong.',
  fill_website: 'Please fill out corporate website.',
  eng: 'English',
  dut: 'Dutch',
  fre: 'French',
  ger: 'German',
  esp: 'Spanish',
  location_req: 'Location name is required.',
  location_wrong: 'Location name is wrong.',
  fill_location: 'Please fill out location name.',
  loc_tckt_req: 'Location on ticket is required.',
  loc_tckt_wrong: 'Location on ticket is wrong.',
  fill_loc_tckt: 'Please fill out location on ticket.',
  street_req: 'Street is required.',
  fill_street: 'Please fill out street.',
  postal_req: 'Postal code is required.',
  postal_wrong: 'El código postal no es correcto',
  fill_postal: 'Please fill out postal code.',
  city_req: 'El lugar es obligatorio',
  fill_city: 'Please fill out city.',
  country_req: 'Country name is required.',
  country_fill: 'Please fill out country name.',
  label_selectProxyName: '--Select proxy name--',
  createGenMeet_TimePickerTooltip:
    'With the cursor in hour or minute fields, you can use up/down arrow keys to increase/decrease the time.',
  label_callIntermediary: 'Llame al intermediario',
  label_lei: 'LEI',
  label_error_leiWrong: 'LEI is wrong',
  uploadPositionStatementFromIntermediary:
    'Cargar la panorámica de posición del intermediario',
  intermediaryAcceptVoteCheckbox1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder, and represent and warrant his true identity.',
  intermediaryAcceptVoteCheckbox2:
    'We hereby confirm that the unique identification number is the same as the number provided to us by the aforementioned Securities Holder.',
  intermediaryAcceptVoteCheckbox3:
    'We hereby confirm that the aforementioned Securities Holder holds the mentioned Securities of the Issuing Institution at the record date.',
  intermediaryAcceptVoteCheckbox4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted by the aforementioned Securities Holder in connection with this General Meeting and that we nor the aforementioned Securities Holder will submit voting instructions other than those being submitted via the Website.',
  intermediaryAcceptVoteCheckbox5:
    'We acknowledge that we have read, understood and consented to the ',
  intermediaryAcceptVoteCheckbox6:
    ', as well as having saved, printed or sent ourselves a copy thereof.',
  label_viewMore: 'Mostrar más',
  label_addressReq: 'La dirección es obligatoria',
  label_initialsReq: 'Las iniciales son obligatorias',
  bulkUpload_processMsg:
    'File is being processed. Please wait while processing the request.',
  bulkUpload_successMsg: 'File processed successfully.',
  bulkUpload_errorMsg: 'There are some errors: ',
  label_sessionLoggedOut:
    'You have not used the portal for 20 minutes and have been logged out automatically. Please click outside this message window to dismiss it.',
  label_sessionWarning:
    'Session is about to expire in 5 minutes. Please respond to avoid logging out',
  label_warning: 'Warning',
  label_gmActionList: 'General Meetings – Action List',
  label_dwtActionList: 'Tax Reclaim – Action List',
  label_decline: 'Decline',
  areSureAccept_modal: 'Are you sure you want to accept this Meeting?',
  areSureDecline_modal:
    'Are you sure you want to decline the role of vote collector for this meeting?',
  label_acceptedVc: 'accepted',
  label_declinedVc: 'declined',
  label_youHave: 'You have ',
  label_roleOfVC: ' the role of vote collector for meeting ',
  label_ofIssuer: ' of Issuer ',
  label_youCanAttend: 'You can vote/attend from ',
  label_dealineDateError: 'You cannot vote for this event – deadline date was ',
  label_registerText: '¿Aún no tiene cuenta de usuario?',
  label_createAccountButton: 'Cree una nueva cuenta',
  areSureAccept_modalVc:
    'Are you sure you want to accept the role of vote collector for this meeting?',
  label_requestNumber: 'Request#',
  label_groupAddSuccess: 'New group added successfully',
  label_groupEditSuccess: 'User group updated successfully',
  label_on: 'en',
  label_uploadPosStatement_fromInt:
    'Upload Position statement from Intermediary',
  label_logout: 'Log out',
  label_intPhoneNumber: 'Intermediary phone number',
  phoneNumber_error: 'El teléfono no es válido',
  confirmFor_sendToIssuer:
    'Are you sure you want to send this meeting to issuer?',
  confirmUser_editDetails: 'Are you sure you want to edit user details?',
  confirmGroup_editDetails: 'Are you sure you want to edit this group details?',
  confirmApprove_Gm:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting.',
  confirmApprove_GmOtherLang:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting in all available languages.',
  pdfFile_formatError: 'Please upload document in PDF file format',
  label_createNewRequest: 'Create new request',
  label_cancelRequestBodyModal:
    '¿Está seguro de que desea cancelar esta solicitud?',
  label_cancelRequestHeaderModal: 'Confirme solicitud de cancelación',
  label_viCancellationText1:
    'Quiere cancelar las instrucciones de voto. Una vez que lo haya confirmado, deberá ponerse en contacto con su intermediario para tramitar la cancelación.',
  label_intermediaryDepartment: 'Intermediary Department',
  label_loginId: 'Dirección de correo electrónico',
  label_password: 'Contraseña',
  label_searchByIssuer: 'Busque por entidad emisora',
  label_DobError: 'Date of birth should not be future date',
  label_registerButton: 'Regístrese',
  label_noOfSharesError: 'Please enter number of shares.',
  label_viewIssuers: 'View issuers',
  label_viewIntermediaries: 'View intermediaries',
  label_viewProxies: 'View proxies',
  label_viewVCs: 'View vote collectors',
  label_viewAgentUser: 'View agent user',
  label_viewIntermediaryUser: 'View intermediary user',
  label_viewProxyUser: 'View proxy intermediary user',
  label_viewIssuerUser: 'View issuer user',
  label_viewVCUser: 'View vote collector user',
  label_addAgentUser: 'Add agent user',
  label_addIntermediaryUser: 'Add intermediary user',
  label_addProxyUser: 'Add proxy intermediary user',
  label_addIssuerUser: 'Add issuer user',
  label_addVCUser: 'Add vote collector user',
  label_confirmVIRequest_header: 'Confirme las instrucciones de voto',
  label_confirmVI_registration_header: 'Confirme inscripción',
  areYouSure_confrimVI_message:
    'Are you sure you want to confirm attendance request?',
  label_confirmAttendRequest_header: 'Confirme inscripción',
  areYouSure_confrimAttend_message:
    'Are you sure you want to confirm attendance request?',
  callIntermediary_SorryModalbody1:
    'Su intermediario aún no nos ha facilitado sus datos de contacto. ¿Desea ponerse en contacto con ABN AMRO Corporate Broking a través de ',
  callIntermediary_SorryModalbody2:
    ' o corporate.broking@nl.abnamro.com para que podamos ponerle en contacto con el departamento adecuado de su intermediario?',
  label_callIntermediary_VI_registration:
    'Gracias por sus instrucciones de voto. Aún tiene que inscribirse a través de su intermediario. ',
  label_callIntermediary_AR_registration:
    'Se ha inscrito para asistir a la junta, pero su intermediario aún no ha autorizado la inscripción.',
  label_ask_provideIdentification_VI:
    'Por tanto, póngase en contacto con su intermediario y confirme el siguiente número de identificación:',
  label_sh_vi_modalBody2:
    'Es posible que su intermediario no conozca este número. En tal caso, su intermediario puede ponerse en contacto con ABN AMRO (AVA@nl.abnamro.com).',
  label_sh_vi_modalBody3:
    'Si tiene alguna pregunta, no dude en ponerse en contacto con ABN AMRO (corporate.broking@nl.abnamro.com)',
  label_toRegister_callIntermediary:
    'Llame a su intermediario para confirmar sus instrucciones de voto.',
  label_ask_provideIdentification:
    'A continuación, se le pedirá el número de identificación:   ',
  label_contactInfo: 'Datos de contacto',
  label_telephoneNumber: 'Teléfono',
  label_deptPhoneNumber: 'Teléfono',
  lable_onlyNumbers_error: 'Please enter number of shares',
  label_createNewAR: 'Inscribirse en la junta',
  label_createNewVI: 'Enviar instrucciones de voto',
  label_editVotingIns: 'Vea las instrucciones de voto',
  label_createPE: 'Create PE',
  label_bulkUploadQrf: 'Bulk QRF',
  label_dwt_notification_link: 'DWT notification link',
  label_recipient: 'Recipient',
  label_downloadQRFBulkTemplate: 'DWT QRF Bulk upload template',
  label_bulkUploadQRF: 'Bulk upload QRF claim',
  label_request_date_time: 'Request date/time',
  label_requested_new_end: 'Requested new end date/time',
  label_QRF_PE: 'QRF / PE',
  label_current_QRF_PE_End_date: 'Current QRF/PE End date',
  label_reason_for_extension_request: 'Reason for extension request',
  label_document_download: 'Document download',
  label_downloadclaimoverview: 'Download Claims Overview',
  label_cc: 'cc',
  label_vebDefault: 'VEB [Estándar]',
  label_SelectSecurityClass: '--Seleccione el tipo de acciones--',
  label_voteAttendEnd:
    'Ya no es posible enviar instrucciones de voto ni inscribirse en esta junta',
  label_voteAttendStart:
    'Aún no es posible enviar instrucciones de voto ni inscribirse en esta junta',
  confirm_proxyLinking: 'Are you sure you want to link this intermediary?',
  label_modalAttReqActionErrMsg:
    'You cannot accept/reject requests for this General Meeting as the intermediary deadline date is already crossed',
  max4000_characters_error: 'Maximum 4000 characters are allowed.',
  label_allSectionNotFilled:
    'Please fill all the sections with valid input and proceed.',
  label_terms_IN_line1:
    'We hereby confirm that we have verified the identity of the aforementioned shareholder and warrant his true identity.',
  label_terms_IN_line2:
    'We hereby confirm that the aforementioned shareholder is in possession of the mentioned securities of the Company at the record date. Should the Company not use a record date, then we hereby warrant that the aforementioned shareholder is in possession of the mentioned securities of the Company at the General Meeting date.',
  label_terms_IN_line3: `In addition, we hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned shareholder as registered for this General Meeting, with right of substitution, to represent, act and vote on the shareholder's behalf, in accordance with the shareholder's instructions,  at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Company, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.`,
  label_terms_IN_line4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned shareholder in connection with this General Meeting and that we nor the aforementioned shareholder will submit voting instructions other than those being submitted via this portal on the Website.',
  label_scroll_toAccept:
    'Desplácese hacia abajo para leer y aceptar las condiciones de uso.',
  acceptTc_shRegistration:
    'Confirmo que he tomado nota de las condiciones de uso, entiendo y acepto su contenido y he conservado, impreso o enviado una copia a mí mismo.',
  label_shRegistration_infoMsg:
    'Le rogamos que nos facilite los mismos datos personales que tiene su entidad financiera. ',
  label_shRegistration_infoMsg2:
    'Utilizamos los datos que introduce para registrar sus votos para la junta general o para enviarle un certificado de inscripción. ¿Quiere saber más cosas sobre cómo tratamos sus datos? Véanos ',
  label_shRegistration_infoMsg3: 'Privacy Statement.',
  file_sizeMsg25MB: ' MB. Please upload a file less than or equal to 25 MB.',
  label_all: '--Todo--',
  contact_heading1: 'ABN AMRO Bank N.V. Corporate Broking',
  label_shareholderLogin: 'Inicio de sesión de accionistas',
  intermediary_notAllowedToVote:
    'You are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.',
  intermediary_noPOAAvailableMessage: `There are no valid PoA's in place, so at this moment you are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.`,
  intermediary_intermediaryPOAListCheckbox:
    'We hereby confirm that we only upload on behalf of the following Intemediaries, for which a valid power of attorney is available.',
  intermediary_modalcheck1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder(s), and represent and warrant his/their true identity.',
  intermediary_modalcheck2:
    'We hereby confirm that the aforementioned Securities Holder(s) hold(s) the mentioned Securities of the Issuing Institution at the record date.',
  intermediary_VI_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to represent, act and vote on behalf of the Securities Holder(s), in accordance with the Securities Holder(s) instructions, at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.',
  intermediary_modalcheck4:
    'We hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned Securities Holder(s) in connection with this General Meeting and that we nor the aforementioned Securities Holder(s) will submit voting instructions other than those being submitted via the Website.',
  intermediary_modalcheck5:
    'We hereby confirm that we have uploaded the individual voting instruction(s) for which we have submitted a total voting instruction (block vote)',
  intermediary_modalcheck6:
    'We hereby confirm that we will provide the registration certificate(s) to the Securities Holder(s) and instruct the Securities Holder(s) to bring the registration certificate(s) to the General Meeting. In case of the appointment of a Proxy Holder, we hereby confirm that we will provide the Proxy(s) to the Securities Holder(s) and instruct the Securities Holder(s) to print, manually sign and provide this Proxy to the Proxy Holder, who must bring this manually signed document to the General Meeting.',
  intermediary_AR_reject_modalcheck4:
    'We hereby confirm that we will instruct the Securities Holder(s), where applicable, to collect the manually signed document from the Proxy Holder(s).',
  intermediary_AR_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to register the Securities Holder(s) for participation for this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration procedure for this General Meeting.',
  intermediary_BulkUpload_int_checkbox5: 'We hereby appoint',
  intermediary_BulkUpload_int_checkbox5_2:
    'as Proxy Holder to represent the Securities Holder(s) listed in this bulk upload at the General Meeting of ',
  intermediary_BulkUpload_int_checkbox5_3: `(the \"Company\") on`,
  intermediary_BulkUpload_int_checkbox5_4: ` and to exercise the voting rights attached to the Securities listed in this bulk upload on the Securities Holder(s)' behalf in this General Meeting.`,
  intDeadline_shdedline_Tooltip:
    'Please note that for the Belgium market this date is always the six calendar day before the date of the meeting.',
  label_proxy: 'Poder',
  label_participation_by_proxy : 'Participación por Poder',
  label_vote_by_correspondence: 'Votación por correspondencia',
  label_participation_method: 'Participation Method',
  label_select_participation_method: '--Select Participation method--',
  vi_intermediary_individual_disclaimer1: 'Por la presente nombramos ',
  vi_intermediary_disclaimer1: 'Por la presente nombro/nombramos a ',
  vi_intermediary_disclaimer2: ' de ',
  vi_intermediary_disclaimer3:
    ' como Apoderado (con derecho de sustitución) para representar al citado Titular de Valores en la Junta General de ',
  vi_intermediary_disclaimer4: ' (la "Sociedad") que se celebrará el ',
  vi_intermediary_disclaimer5: ' a las ',
  vi_intermediary_disclaimer6: ' en ',
  vi_intermediary_disclaimer7:
    ' y para ejercer los derechos de voto inherentes a dichos Valores en nombre del Titular de Valores en la Junta General.',
  vi_intermediary_disclaimer_telenet0:
    'Si no hay instrucciones de voto para el apoderado con respecto a los distintos puntos del orden del día, o en caso de que, por cualquier motivo, hubiera ambigüedad en las instrucciones de voto comunicadas, o si las decisiones que deba adoptar la junta general sobre el orden del día durante la junta tuvieran que votarse con sujeción al cumplimiento del Código de Sociedades, el apoderado votará siempre a favor de la decisión propuesta por el Consejo de Administración, eventualmente tal como se haya enmendado.\n',
  vi_intermediary_disclaimer8:
    'Por la presente, doy/damos al Apoderado las siguientes instrucciones para votar en la Junta General de la Sociedad:',
  vi_vote_by_correspondence_disclaimer: 'Su voto se asignará directamente al emisor de la reunión y su voto se comunicará inmediatamente al emisor después de la confirmación del derecho de su intermediario.',
  vi_shareholder_disclaimer1: 'Por la presente nombro/nombramos a ',
  vi_shareholder_disclaimer2: ' de ',
  vi_shareholder_disclaimer3:
    ' como Apoderado (con derecho de sustitución) para estar presente en mi/nuestro nombre en la Junta General de ',
  vi_shareholder_disclaimer4: ' (la "Sociedad") que se celebrará el ',
  vi_shareholder_disclaimer5: ' a las ',
  vi_shareholder_disclaimer6: ' en ',
  vi_shareholder_disclaimer7:
    'para participar en mi/nuestro nombre en las deliberaciones y ejercer los derechos de voto inherentes a los citados Valores de conformidad con las instrucciones de voto mencionadas anteriormente y a firmar todos los documentos o realizar todas las demás acciones oportunas para confirmar los votos emitidos. ',
  vi_shareholder_disclaimer8:
    'Por la presente doy/damos a la Entidad que recopila las instrucciones de voto las instrucciones mencionadas anteriormente para que vote en mi/nuestro nombre en la Junta General de la Sociedad.',
  vi_shareholder_disclaimer8_with_right_of_substitution:
    'Por la presente doy/damos al Recaudador de Votos (con derecho de sustitución) las citadas instrucciones para que vote en mi/nuestro nombre en la Junta General de la Sociedad.',
  label_tou_registration: 'Inscripción',
  label_tou_registration_definitions:
    'inscripción para participar en una Junta General específica a través del Portal de Inscripción;',
  label_tou_registration1: 'Portal de Inscripción',
  label_tou_registration_definitions1:
    'la parte del Sitio Web a través de la cual el Usuario podrá (i) completar la inscripción para participar en una Junta General específica, así como (ii) designar a cualquier Apoderado y dar instrucciones de voto para una Junta General específica;',
  'label_terms_regSection4.1':
    'El Portal de Inscripción podrá utilizarse para inscribirse para participar en una Junta General específica.',
  'label_terms_regSection4.2':
    'Para completar la inscripción, el Usuario deberá ("Procedimiento de Firma"):',
  'label_terms_regSection4.3':
    'El Usuario deberá cumplimentar de buena fe los datos solicitados en los campos correspondientes del Portal de Inscripción del Sitio Web. Los datos introducidos por el Usuario en el Portal de Inscripción serán verificados, tras la recepción de una alerta por correo electrónico de ABN AMRO, por el Intermediario especificado en el Portal de Inscripción por el Usuario. El Intermediario informará a ABN AMRO de cualquier dato incorrecto que haya facilitado. A continuación, el Intermediario informará de ello al Usuario, que podrá modificar los datos incorrectos hasta el final del periodo en el que el Usuario puede utilizar el Portal de Inscripción para una Junta General específica. El Intermediario verificará los datos modificados y, si todos los datos son correctos, el Usuario recibirá una notificación de ABN AMRO. Si el Usuario no corrige los datos incorrectos dentro del plazo durante el cual puede utilizar el Portal de Inscripción para una Junta General específica, la Inscripción de participación no se podrá completar y se considerará que no ha tenido lugar.',
  'label_terms_regSection4.21':
    'proporcionar el Número Único de Identificación que aparece en el Portal de Inscripción al Intermediario, que verificará este número y la identidad del Usuario;',
  'label_terms_regSection4.22':
    'leer y volver a confirmar la aceptación de las presentes Condiciones de uso; y',
  'label_terms_regSection4.23': 'confirmar su inscripción.',
  'label_terms_regSection4.24':
    'Al completar el Procedimiento de Firma descrito anteriormente, el Usuario reconocerá y aceptará que ha firmado de forma válida la Inscripción (también en caso de que un tercero, con o sin consentimiento, haya firmado la Inscripción) con una firma electrónica. El Usuario reconocerá y aceptará que la firma electrónica utilizada en el Portal de Inscripción, en concreto el Procedimiento de Firma, tendrá los mismos efectos jurídicos que una firma manuscrita.',
  'label_terms_proxySection4.4new':
    'El Apoderado gozará en la Junta General de los mismos derechos que el Titular de Valores representado de esa manera y, en particular, el derecho a intervenir, a formular preguntas y a ejercer el derecho de voto.',
  'label_terms_regSection4.4.1new':
    'proporcionar el Número Único de Identificación que aparece en el Portal de Poderes al Intermediario, que verificará este número y la identidad del Usuario;',
  'label_terms_regSection4.4.2new':
    'leer y volver a confirmar la aceptación de las presentes Condiciones de uso; y',
  'label_terms_regSection4.4.3new': 'confirmar su inscripción.',
  'label_terms_proxySection4.6':
    'El Poder no será una solicitud para la concesión de un poder.',
  'label_terms_proxySection4.7':
    'El Poder otorgado para una Junta General específica se aplicará a las sucesivas Juntas Generales convocadas con el mismo orden del día.',
  'label_terms_proxySection4.8':
    'Los Poderes que se otorgan antes de la publicación de un orden del día suplementario, si corresponde, seguirán siendo válidos para los puntos del orden del día a los que se refieran. El Apoderado no estará autorizado a votar sobre los nuevos puntos del orden del día a tratar sin instrucciones.',
  'label_terms_generalProvisions11.5.2new':
    'A Consumer domiciled in Belgium may bring proceedings in relation these Terms of Use and/or the use of the Website before the competent court in the Netherlands, or before the competent court in Belgium. In the latter case, the applicable laws shall be the laws of Belgium.',
  'label_terms_generalProvisions11.5.3new':
    'Proceedings in relation to these Terms of Use and/or the use of the Website may be brought against a Consumer domiciled in Belgium only before the court in Belgium. In this case, the applicable laws shall be the laws of Belgium.',
  'label_terms_definitions1.5header': 'Valores',
  'label_terms_definitions1.6header':
    'Procedimiento de Firma del Titular de Valores',
  locale_tou_and: 'y',
  label_tou_securitiesHolder: 'Intermediario',
  label_tou_termsUse: 'Poder',
  label_tou_uniqueIdentificationNumber: 'Apoderado',
  label_tou_user: 'Portal de Poderes',
  label_tou_header4: 'Inscripción',
  'label_tou_header4.1': 'INSCRIPCIÓN POR PARTE DEL TITULAR DE VALORES',
  'label_terms_regSHSection4.1.1':
    'A través del Portal de Inscripción, un Titular de Valores podrá solicitar participar en una Junta General específica.',
  'label_terms_regSHSection4.1.2':
    'Para completar la Inscripción (o la revocación de la misma), el Titular de Valores deberá ("Procedimiento de Firma del Titular de Valores"):',
  'label_terms_regSHSection4.1.21':
    'proporcionar el Número Único de Identificación que aparece en el Portal de Inscripción al Intermediario, que verificará este número, la identidad y la capacidad del Titular de Valores;',
  'label_terms_regSHSection4.1.22':
    'leer y volver a confirmar la aceptación de las presentes Condiciones de uso; y',
  'label_terms_regSHSection4.1.23': 'confirmar su inscripción.',
  'label_terms_regSHSection4.1.3':
    'Al completar el Procedimiento de Firma del Titular de Valores que se ha descrito, el Titular de Valores reconocerá y aceptará que ha firmado válidamente la Inscripción (o la revocación de la misma) (incluso en el caso de que un tercero, con o sin consentimiento, haya firmado la Inscripción (o la revocación de la misma)) con una firma electrónica. El Titular de Valores reconocerá y aceptará que la firma electrónica utilizada en el Portal de Inscripción, en concreto el Procedimiento de Firma del Titular de Valores, tendrá los mismos efectos jurídicos que una firma manuscrita.',
  'label_terms_regSHSection4.1.4':
    'Una vez completada la Inscripción, el Titular de Valores recibirá un certificado de inscripción que deberá llevar a la Junta General.',
  'label_terms_regSHSection4.1.5':
    'El Titular de Valores deberá cumplimentar de buena fe los datos solicitados en los campos correspondientes del Portal de Inscripción del Sitio Web. Los datos introducidos por el Titular de Valores en el Portal de Inscripción serán verificados, tras la recepción de una alerta por correo electrónico de ABN AMRO, por el Intermediario especificado en el Portal de Inscripción por el Titular de Valores. El Intermediario informará a ABN AMRO de cualquier dato incorrecto que haya facilitado. A continuación, el Intermediario informará de ello al Titular de Valores, que podrá modificar los datos incorrectos hasta el final del periodo en el que el Titular de Valores puede utilizar el Portal de Inscripción para una Junta General específica. El Intermediario verificará los datos modificados y, si todos los datos son correctos, el Titular de Valores recibirá una notificación de ABN AMRO. Si el Titular de Valores no corrige los datos incorrectos dentro del plazo durante el cual puede utilizar el Portal de Inscripción para una Junta General específica, la Inscripción de participación no se podrá completar y se considerará que no ha tenido lugar.',
  'label_tou_header4.2': 'INSCRIPCIÓN POR PARTE DEL INTERMEDIARIO',
  'label_terms_regSHSection4.2.1':
    'A través del Portal de Inscripción, un Intermediario, actuando en nombre y por cuenta de un Titular de Valores, podrá inscribir a un Titular de Valores para que participe en una Junta General específica.',
  'label_terms_regSHSection4.2.2':
    'Para completar la Inscripción (o su revocación), el Intermediario deberá ("Procedimiento de Firma del Intermediario"):',
  'label_terms_regSHSection4.2.21':
    'verificar y confirmar la identidad y la capacidad del Titular de Valores;',
  'label_terms_regSHSection4.2.22':
    'leer y volver a confirmar la aceptación de las presentes Condiciones de uso; y',
  'label_terms_regSHSection4.2.23': 'confirmar la inscripción.',
  'label_terms_regSHSection4.2.3':
    'Al completar el Procedimiento de Firma del Intermediario que se ha descrito, el Intermediario reconocerá y aceptará que ha firmado válidamente la Inscripción (o la revocación de la misma) (incluso en el caso de que un tercero, con o sin consentimiento, haya firmado la Inscripción (o la revocación de la misma) con una firma electrónica. El Intermediario reconocerá y aceptará que la firma electrónica utilizada en el Portal de Inscripción, en concreto el Procedimiento de Firma del Intermediario, tendrá los mismos efectos jurídicos que una firma manuscrita.',
  'label_terms_regSHSection4.2.4':
    'Una vez completada la inscripción, el Intermediario recibirá un certificado de inscripción para el Titular o Titulares de Valores que haya registrado. El Intermediario deberá facilitar este certificado de inscripción al Titular o Titulares de Valores, que deberá(n) llevarlo a la Junta General.',
  'label_terms_regSHSection4.2.5':
    'El Intermediario deberá cumplimentar de buena fe los datos solicitados en los campos correspondientes del Portal de Inscripción del Sitio Web.',
  'label_terms_regSHSection4.2.6':
    'El intermediario que utilice el Portal de Registro, actuando en nombre y por cuenta de un Titular de Valores, deberá (i) hacerlo únicamente sobre la base y dentro de los límites de un poder legítimo, (ii) cumplir todos los requisitos en virtud de dicho poder o en relación con el mismo, y (iii) presentar los documentos que acrediten la facultad de actuar en nombre y por cuenta del Titular de Valores a ABN AMRO, el Emisor, el notario o cualquier otra persona encargada del procedimiento de inscripción o apoderamiento para una Junta General específica en cuanto se lo solicite.',
  label_downloadPDF_voting: 'Voting instruction per registration PDF',
  label_downloadExcel_voting: 'Voting instruction per registration Excel',
  label_accountLocked:
    'La cuenta de usuario está bloqueada, restablezca la contraseña.',
  label_technicalError: 'Error técnico, vuelva a intentarlo más tarde',
  label_sessionExpired:
    'La sesión de usuario ha expirado, inicie sesión para continuar',
  label_selectVC: '--Seleccione entidad--',
  label_confirmVotes_prevGm:
    'Instrucciones de voto confirmadas de juntas anteriores',
  label_no_ar_available: 'No hay inscripciones',
  label_no_vi_available: 'No hay instrucciones de voto',
  label_approved_RC: 'Aprobada, solicitud de cancelación presentada',
  label_cancellation_requested: 'Solicitud de cancelación presentada',
  extnbtn3: 'Cancelled',
  label_noAR: 'No attendance requests available in this status',
  label_noVI: 'No voting instructions available in this status',
  label_no_confVI_available: 'No hay instrucciones de voz confirmadas',
  label_votingExists_error:
    'Instrucciones de voto ya existentes para este tipo de seguridad e intermediario. Véase "Mis inscripciones/votos emitidos',
  label_cancelReg_info:
    'Las inscripciones que figuran a continuación se cancelarán una vez que su intermediario confirme las instrucciones de voto',
  label_cancelVoting_info:
    'Las instrucciones de voto que figuran a continuación se cancelarán una vez que su intermediario haya confirmado esta solicitud de inscripción',
  label_noVCavailable: 'No vote collector available',
  label_noIntermediaryAvailable: 'No intermediary available',
  label_notVotable: 'No hay punto de votación',
  label_notVotableAgenda: 'No hay puntos de votación para esta junta',
  modal_header_selectLang: 'Seleccione un idioma para la descarga',
  label_accessDate: 'Fecha de inicio de la inscripción',
  mail_sentToIssuer_text1: 'Mail has been sent already on ',
  mail_sentToIssuer_text2: '. Please check before sending again.',
  label_updatePwd: 'Cambiar contraseña',
  modal_areYousure_changePwd:
    '¿Está seguro de que quiere cambiar la contraseña?',
  label_update_accountDetails: 'Actualizar perfil',
  modal_areYouSure_updateDetails:
    '¿Está seguro de que quiere actualizar el perfil?',
  label_noGMMessage: 'No general meeting available',
  label_proxyTitle: 'Proxy title',
  label_proxyCountry: 'Proxy country',
  label_proxyCity: 'Proxy city',
  label_noDataGMIssuerMessage: 'No general meeting available for issuer',
  label_utc: 'UTC',
  label_uploadBOFile: 'Upload BO file',
  label_uploadBOFileError:
    'Uploading a BO file in ".xlsx" is mandatory if the user type is NOMI or TRUS',
  label_checkShareholder: 'Check for shareholder',
  label_edit_IN_AR_BOFile: 'Cambiar la inscripción',
  label_total_voting_instrunction: 'Total voting instructions',
  label_voting_inst_per_reg: 'Voting instruction per registration',
  label_pdf: 'Pdf',
  label_excel: 'Excel',
  label_cancelReq: 'Cancelar solicitud',
  label_reqCancellation: 'Solicitud de cancelación',
  label_dnldRegCerti: 'Descargar el certificado de inscripción',
  label_addIntSucessMsg: 'La demande d’intermédiaire a été créée',
  label_pwdSuccessMsg: 'Se ha actualizado la contraseña',
  lable_accountUpdateSuccessMsg: 'Se ha actualizado la cuenta de usuario',
  label_noShareclassAvailable: 'No shareclass available',
  label_selectRegCertLang: 'Select registration certificate language',
  label_sendRegTicketToShareholder:
    'Send registration tickets to shareholders based on provided mail address after upload in ',
  label_pn: 'Numéro de téléphone',
  label_coc: 'Cámara de Comercio',
  label_vat: 'Número de IVA',
  label_contact_details_footer: 'Datos de contacto',
  label_noSHGMFound: 'Actualmente no hay juntas generales',
  label_voting_disabled: 'La votación está desactivada para esta junta.',
  label_attendance_disabled: 'La asistencia a esta junta está desactivada.',
  checkPattern_Pwd_length: 'La contraseña debe tener al menos 8 caracteres',
  label_DobInvalid: 'La fecha de nacimiento es obligatoria (dd-MM-aaaa)',
  label_dobFutureError: 'La fecha de nacimiento no puede estar en el futuro',
  label_dateFutureError: 'La fecha no puede estar en el futuro',
  label_dateInvalid: `Introduzca la fecha en 'dd-MM-aaaa'`,
  label_voteNotPossible:
    'No es posible enviar instrucciones de voto para esta junta',
  label_attendNotPossible: 'No es posible inscribirse en esta junta',
  label_attendNotEnabled1:
    'Por desgracia, (ya) no es posible que los accionistas asistan a la Junta General de Accionistas. Puede ponerse en contacto con',
  label_attendNotEnabled2:
    'Las instrucciones de delegación/voto pueden darse utilizando el botón "mediante instrucciones de voto". Cuando esté disponible la asistencia virtual, los accionistas podrán inscribirse en ella a través del botón "asistir virtualmente"',
  label_attendDisabled1_intermediary:
    'It is unfortunately not possible for shareholders to attend the meeting in person. Therefor create new registration and also bulk upload is not allowed. Your clients (shareholders) should contact the company ',
  label_attendDisabled2_intermediary:
    ' for approval to attend the meeting in person.',
  label_voteAttendNotAllowed:
    'Ya no es posible enviar instrucciones de voto ni inscribirse en esta junta',
  label_voteAttendNotAllowedAccessDate:
    'Votar / Asistir se activará después de la fecha de acceso de los accionistas',
  label_shareholderExists:
    'Esta dirección de correo ya está registrada, puede conectarse directamente o solicitar una nueva contraseña si no la recuerda.',
  label_oldPassIncorrect: 'La contraseña antigua no es correcta',
  label_existLastPassList:
    'Esta contraseña se encuentra en la lista de las 12 últimas contraseñas',
  label_passLengthIncorrect: 'La longitud de la contraseña es incorrecta',
  label_dataNotFound: 'No se han encontrado datos para la entrada indicada',
  label_passNotMatch: 'La contraseña no coincide',
  label_accessDenied: 'Acceso denegado',
  label_globalError_1:
    'Lo sentimos, se ha producido un error en la edición solicitada. Puede que funcionen otras ediciones, pero no se puede garantizar.',
  label_globalError_2:
    'Para comunicarse directamente con el servicio de asistencia técnica, envíe un correo electrónico a corporate.broking@nl.abnamro.com con detalles de la acción que ha realizado y nos pondremos en contacto con usted.',
  label_error: '¡Incorrecto!',
  error_shareClass_not_created_invalid_participation_data:
      'Invalid Participation method selection, Please fill valid information',
  label_newVote: 'Nuevas instrucciones',
  label_newRegistration: 'Nueva inscripción',
  label_createRTS: 'Create new RTS request',
  label_editRTS: 'Edit RTS request',
  label_continue: 'Continue',
  label_conf_votes: 'Instrucciones de voto confirmadas',
  lable_AR_proxy_error:
    'La inscripción (para asistir a la junta propiamente dicha) ya está disponible para esta junta',
  lable_AR_shares_error:
    'No puede votar ni inscribirse en esta junta porque con el número de acciones que especifica superará el número total de acciones en circulación para esta clase de acciones.',
  label_edit_extn_request: 'Edit Extension Request',
  label_shGMNotFound: 'Actualmente no hay juntas generales disponibles ',
  label_forYourSearchTerm: 'para su búsqueda',
  label_defaulProxyForbulkUpload: `Add 'Iedere vertegenwoordiger van de VEB' as proxy for all attendance requests in this upload file`,
  label_request_date: 'Request date',
  label_dateandtime: 'Date and time',
  label_exclamation: '!',
  label_noDWTavailable: 'There are no DWT events available',
  label_noDataGMIssuerMessage_DW: 'No DWT events available for issuer',
  label_portalInfoExample:
    'This portal is still under construction and the information shown is an example',
  label_error_nameInvalid:
    'El nombre no es válido Debe haber al menos 2 caracteres válidos',
  label_thereAreNo: ' There are no',
  label_eventsAtMoment: 'tax reclaim events at this moment',
  label_draft: 'draft',
  label_pending: 'pending',
  label_toBePublished: 'to be published',
  label_live: 'live',
  label_completed: 'completed',
  label_cancelled: 'cancelled',
  label_documentLessThan25mb: 'Document should be less than 25mb',
  label_iWouldAttend: 'Deseo inscribirme para asistir a la junta',
  label_iWouldVote: 'Deseo enviar instrucciones de voto para la junta',
  label_checkSCMsg:
    'Add check to prevent register or vote to wrong share class',
  label_sendEmailEuro: 'Send email to Euroclear',
  label_downloadEuroclearReport: 'Download Euroclear report',
  label_agentWS: 'Agent workstation',
  label_sendVIreg_toEuroclear:
    'Option to send registrations and voting instructions to Euroclear',
  label_proceed: 'Continuar',
  label_dear: 'Dear ',
  label_noShareholderDetails: 'No shareholder details found',
  label_euroclear_mesage: 'Mail has been sent already on',
  label_plsCheck_mesage: 'Please check before sending again.',
  label_reSendEmailEuro:
    'Mail has been already sent. Do you want to send it again?',
  label_subject_required: 'Subject is required.',
  label_emailboday_required: 'Email body is required.',
  sendVIreg_toEuroclear_Tooltip: 'This option is mandatory for Shell',
  label_nwkError_1: 'Please validate your internet connectivity and check:',
  label_download_intermediary_instructions: 'Download accepted instructions',
  label_nwkError_2: '-- Checking the network cables, modem, and router',
  label_nwkError_3: '-- Reconnecting to Wi-Fi',
  label_nwkError_4:
    '-- Refresh the browser (press F5) once the connectivity issue is resolved',
  label_network_error_lost: 'Internet connection lost',
  label_network_error_1:
    'The page could not be loaded as there is or was an issue with your internet connection.',
  label_network_error_2:
    'Please check your connection and refresh the browser (press F5) once the connection issue is resolved.',
  label_noVCavailable_forVote:
    'This general meeting and shareclass does not have any vote collector',
  label_aegon_issuer:
    'La junta de accionistas puede seguirse en directo en el sitio web de Aegon',
  label_instruction_not_available:
    'There are no accepted instructions to download',
  vi_intermediary_disclaimer_telenet1:
    'IDw Consult BV, con Bert De Graeve como representante permanente, es administrador de Telenet Group Holding NV y, por lo tanto, tiene un posible conflicto de intereses según lo estipulado en el artículo 7:143, §4 del Código de Sociedades y Asociaciones belga. En caso de posible conflicto de intereses, solo votará en virtud de la ejecución del poder de conformidad con las instrucciones de voto específicas incluidas en este poder. En caso de que no haya instrucciones de voto específicas, se considerará que ha recibido las instrucciones específicas de votar a favor de este punto.',
  vi_intermediary_disclaimer_telenet2: '',
  lable_numberOfShares_error: 'Invalid number of shares',
  lable_5posOnly: 'Las iniciales pueden ocupar hasta 5 posiciones',
  label_votingAdvise: 'Voting advise',
  label_shareholder_link: 'www.abnamro.com/shareholder',
  label_intermediary_link: 'www.abnamro.com/intermediary',
  label_proceedToLogout: 'Continuar a ',
  label_asACustomerOf: 'Como cliente de ',
  label_voteViaIntermediary:
    'solo puede inscribirse en la junta a través del sitio de ',
  label_alex_binkBank_warning:
    ' solo puede enviar instrucciones de voto a través del sitio de',
  label_reloadCancelError:
    'Please choose different meeting or a different shareclass to proceed.',
  label_teleNumber: 'Teléfono',
  label_addresseeEmail: 'Dirección de correo electrónico',
  lable_contactDetails: 'Datos de contacto',
  pwdChanged_successMsg_proceed:
    'Password changed successfully. Redirecting to login page...',
  pwdExpiry_msg:
    'Your password is expired, please change your password. Be aware that you cannot use the last 12 used passwords.',
  label_viewVotingInstruction: 'View voting instruction',
  label_login_failed: 'Login redirect failed ',
  label_noVIAvailable: 'No voting instruction available for ',
  label_of: 'o',
  label_pwd_requirement: 'Please check the password requirements',
  label_one_email: `Please ensure that only one valid email id is entered in the 'To' address field`,
  lable_mobile_number: 'Teléfono móvil',
  label_mobile_number_reenter: 'Repita su teléfono móvil',
  lable_mobile_number_guidelines:
    'Seleccione el código de país correcto y facilite su teléfono móvil siguiendo las instrucciones que figuran a continuación:',
  lable_mobile_number_numeric:
    '- El número de móvil sólo podrá estar formado por números',
  lable_mobile_number_length:
    '- La longitud máxima permitida será de 15 números',
  label_mobile_number_numeric_error:
    'El número de móvil deberá constar solo de números.',
  label_phone_number_exists_error: 'Este número de teléfono ya está en uso.',
  label_country_code_error: 'Please select the country code.',
  label_virtual_participation: 'Asistencia virtual',
  label_virtual_participation_no_voting:
    'Asistencia virtual, solo voto por delegación',
  label_attend_virtualy: 'Participación virtual',
  label_attend_sh: 'Inscribirse',
  label_vote_sh: 'Votar',
  label_virtualPartNotAllowedAccessDate:
    'La participación virtual se habilitará después de la fecha de acceso de los accionistas',
  label_register_virtualParticipation_step_counter_1:
    'Acciones y número de cuenta',
  label_register_virtualParticipation_step_counter_2: 'Teléfono, Condiciones',
  label_register_virtualParticipation_step_counter_3: 'Código de verificación',
  label_register_virtualParticipation:
    'Inscribirse para participar virtualmente en una junta',
  label_register_virtualParticipation_verify_phone:
    'Verificación del número de teléfono',
  label_register_virtualParticipation_acknowledge: 'Reconocer y aceptar',
  label_register_virtualParticipation_enter_code: 'Introducir el código',
  label_register_virtualParticipation_success:
    'La inscripción para la participación virtual se ha realizado correctamente',
  label_register_virtualParticipation_success_message:
    'La solicitud de participación virtual en la junta la deberá aprobar su intermediario.',
  label_register_virtualParticipation_please_confirm:
    'Confirme a su intermediario su cuenta de inversión y su teléfono móvil',
  label_register_virtualParticipation_question: 'Pregunta',
  label_register_virtualParticipation_question_message:
    'Si tiene alguna pregunta, no dude en ponerse en contacto con ABN AMRO Bank.',
  label_verfication_code_sent:
    'Se ha enviado un nuevo código de verificación a su teléfono móvil.',
  label_verifying_code: 'Código de verificación...',
  label_verfication_sending_new_code: 'Enviando nuevo código',
  label_otp_resend_message_part1:  'Volver a enviar de nuevo se habilitará en  ',
  label_otp_resend_message_part2: '  segundos. Espere hasta que aparezca el PIN único (OTP).',
  label_send_again: 'Volver a enviar',
  label_code: 'Código',
  label_back: 'Atrás',
  label_verify: 'Verificar',
  label_contact_intermediary: 'Póngase en contacto con el Intermediario',
  label_join_meeting: 'Participar en la junta',
  label_to_my_events: 'A mis eventos',
  label_account: 'Número de cuenta',
  label_terms_vp1:
    'I/We herby confirm that I/We have read, understood and consented the',
  label_terms_vp2:
    'as well as having saved, printed or sent myself/ourselves a copy thereof.',
  label_contact: 'Contacto',
  label_helpdesk: 'Servicio de asistencia',
  label_shares: 'Acciones',
  label_SAN_error: 'Introduzca una cuenta de inversión válida',
  label_SAN_exists_error:
    'Securities account number already exists for this meeting',
  lable_TermsAndConditions: 'Condiciones',
  lable_phoneNumberVerification:
    'Una vez verificado este número de teléfono, ya no se podrá cambiar. En ese caso, deberá utilizarse hasta que se termine la junta.',
  label_virtualParticipationRequests: 'Virtual participation requests',
  label_no_virtualParticipation:
    'No hay solicitudes de asistencia virtual disponibles',
  lable_mobileNumMismatch: 'los números de teléfono introducidos no coinciden',
  label_phoneNumber_exists_meeting_error:
    ' Ya está registrado para esta junta con un número de teléfono diferente. Compruebe y corrija, si es necesario, las inscripciones en su resumen de inscripciones...',
  label_numeric_value_error: 'Introduzca solo números',
  label_meeting_details: 'Datos de la junta',
  label_join: 'Participar',
  label_join_meeting_enable_hover_text:
    'Seleccionar para participar virtualmente en la junta',
  label_join_meeting_disable_hover_text1: ' Puede conectarse a la junta desde',
  label_join_meeting_disable_hover_text2: 'hasta medianoche',
  label_virtual_participation_leave_cbp_warning:
    'Está a punto de salir del portal de votación electrónica de ABN AMRO y acceder a la aplicación/página web de participación virtual. Esta aplicación/página web no la proporciona ni opera ABN AMRO. Por lo tanto, ABN AMRO no será responsable de su contenido, disponibilidad y funcionalidad. Si tiene preguntas o problemas con la aplicación/sitio web de participación virtual, llame al +3120 628 6070.',
  label_button_accept_and_proceed: 'Aceptar y Continuar',
  label_helpDesk_Number: '+31 20 628 6070',
  label_Important_Information: 'Información importante',
  label_cbp_email: 'corporate.broking@nl.abnamro.com',
  label_dual_factor_authentication_success:
    'Se le ha redirigido correctamente a la aplicación/página web de participación virtual',
  label_authentication_generic_message:
    'Por desgracia, no podemos tramitar su solicitud. Póngase en contacto con el servicio de asistencia.',
  label_2FA_OTP_fail:
    'No se puede realizar la creación / verificación del código (OTP). Vuelva a intentarlo.',
  label_Invalid_OTP_msg:
    'Código no válido / caducado (OTP). Vuelva a enviarlo para recibir un nuevo código.',
  CBP_ERR_573: 'Esta cuenta de inversión ya está registrada para esta junta',
  label_like_to_attend_virtually: 'Me gustaría asistir virtualmente a la junta',
  label_virtual_Meeting_Id_mandatory_msg:
    'For virtual participation, meeting id is mandatory',
  label_virtual_participation_provider_required_msg: 'A provider is required',
  label_download_registration_list: 'Download registration list',
  label_vp_registration_risk:
    'A continuación, podrá confirmar que desea asistir virtualmente a la Junta General.\n\nSi desea continuar, reconocerá y aceptará los riesgos inherentes a la asistencia y participación virtuales en la Junta General haciendo clic en la casilla correspondiente que figura a continuación y accederá a la sección Participación Virtual haciendo clic en el botón "Aceptar y continuar".\n\nLos riesgos inherentes a la asistencia y participación virtuales en la Junta General incluyen, entre otros: fallos en los equipos, programas informáticos, infraestructura de red, servidores, conexiones a Internet o telefónicas, equipos de vídeo o voz de la junta virtual que pueden ser o no consecuencia de un incidente de seguridad de la información (incluidos, entre otros, el uso no autorizado, la piratería informática, un ataque de denegación de servicio (DoS), una denegación de acceso, fallos de funcionamiento o intrusión derivados del robo o interceptación ilegal de una contraseña o código de acceso a la red, así como otros tipos de ciberataques) o pueden estar causados por fallos mecánicos, como cortes de electricidad durante la Junta General y otros problemas técnicos imprevistos o interrupciones relacionadas con el Sitio Web en general, la sección de Participación Virtual, la Junta General o los equipos o conexiones utilizados por usted, el accionista.\n\nAl hacer clic en las siguientes casillas, confirmará que:\n',
  label_vp_registration_risk_item1:
    'reconoce y acepta los riesgos descritos y que ha leído y comprendido las condiciones vinculadas al registro y uso de la sección de Participación Virtual.',
  label_vp_registration_risk_item2:
    'está autorizado a aceptar estos riesgos porque usted mismo es el accionista o ha obtenido un poder a tal efecto.',
  label_vp_registration_risk_item3:
    'ha leído, comprendido y acepta la política de voto de los accionistas híbridos/virtuales declarada aplicable por la entidad emisora. Esta política puede consultarse aquí: ',
  label_vp_registration_risk_item3_no_link:
    'que reconoce que, si está disponible, leyó, comprendió y aceptó la política relativa a la reunión/participación híbrida/virtual y la declaración de privacidad declarada aplicable por la institución emisora.',
  label_vp_registration_risk_document:
    'Las condiciones de uso aplicables a la utilización del sitio web en general y de la sección Participación Virtual en particular pueden consultarse aquí: ',
  label_policy_issuing_institution: 'Política de la Entidad Emisora',
  label_virtual_Meeting_date_mandatory_msg: 'Enable join by date is required',
  label_Enable_join_date_error_msg:
    'Enable join by date should be greater than the shareholder access date',
  label_Enable_join_by: 'Enable join by',
  label_voting_options: 'Opciones de voto',
  label_record_date_required: 'Record date is required',
  label_record_date_format:
    'Record date format should be like \n 01-April-2017\n',
  label_record_date_before_GM:
    'Record date should be before general meeting date.',
  label_record_date_after_convocation:
    'Record date should be after convocation date.',
  label_publication_date_required: 'publication date is required.',
  label_publication_date_format:
    'publication date format should be like \n 01-April-2017\n.',
  label_publication_date_before_GM:
    'publication date should be before general meeting date.',
  label_publication_date_after_convocation:
    'publication date should be after convocation date.',
  label_gmProductType: 'GM',
  virtual_participation_allowed_tooltip:
    'Virtual participation can only be disabled when no pending or approved virtual participation requests exist for the meeting',
  registered_share_help_text:
    ' Indique el tipo de acciones con las que desea participar. Si posee acciones a través del registro de accionistas de la sociedad, seleccione registro de acciones.',
  label_intermediaryHelpText:
    'El intermediario es la entidad en la que tiene su cuenta de inversión',
  label_approved_registrations: 'Approved registrations',
  label_approved_securities: 'Approved securities',
  label_pending_registrations: 'Pending registrations',
  label_total_pending_registrations: 'Total pending registrations',
  label_overview_of_live_general_meeting: 'Overview of live general meeting',
  label_modal_header_send_preview: 'Send preview',
  label_modal_confirm_template_updated: 'Template updated',
  label_modal_confirm_preview_sent: 'Preview email is sent',
  label_modal_failed_sent_failed: 'Sending the preview failed',
  label_modal_preview_sending: 'Sending preview...',
  CBP_ERR_604: 'error de verificación reCAPTCHA',
  CBP_ERR_227:
    'Esta dirección de correo ya está registrada, puede conectarse directamente o solicitar una nueva contraseña si no la recuerda.',
  CBP_ERR_572: 'Este número de teléfono ya está en uso.',
  label_status_LIVE: 'Live',
  label_status_PENDING: 'Pending',
  label_enterNumberOfSecurities: 'Indique el número de acciones',
  vi_disclaimer_greenyard1:
    'Si ha otorgado un poder para la junta general extraordinaria de accionistas, el poder cumplimentado y firmado deberá entregarse en formato original a la Sociedad enviándolo por correo a la siguiente dirección: ',
  vi_disclaimer_greenyard2:
    'Greenyard NV, a la atención de Fran Ooms, Strijbroek 10, 2860 Sint-Katelijne-Waver (Bélgica), o bien en la junta general extraordinaria de accionistas para que lo deposite el apoderado. Si la Sociedad no ha recibido el poder original antes del inicio de la junta general extraordinaria de accionistas, este no será válido.',
  ie11_xlsx_download_warning:
    'La descarga de .xlsx no es totalmente compatible con IE11. Si la descarga falla, inténtelo en otro navegador (Chrome, Firefox, Safari o Edge).',
  label_more_info: 'Más información',
  toolTip_doNotPublish:
    'When selected the shareclass will not be published to the market and to normal shareholders. (When all shareclasses of a meeting are selected, the entire meeting itself is invisible to the market and shareholders)',
  label_sh_different_custodian_message: `Si también tiene valores con derecho a voto en otro banco depositario, <a href=\"mailto:${GM_CONTACT_EMAIL}\">póngase en contacto con nosotros</a>.`,
  label_shareholderGmCategory0: 'Juntas con opciones de voto',
  label_shareholderGmCategory1: 'Juntas con fecha límite en el pasado',
  label_shareholderGmCategory2: 'Juntas con fecha de acceso en el futuro',
};
