import en from './localeEn';
import { GM_CONTACT_EMAIL } from '../global/constants';

export default {
  ...en,

  label_hi: 'Hi',
  label_generalMeeting1: 'Assemblées générales',
  label_generalMeetingName: 'General meeting name',
  label_issuer: 'Émetteur',
  label_identificationNumber: "Numéro d'identification",
  label_issuer_name: 'Issuer name',
  label_type: 'Type',
  label_genMeetingDate: 'Date d’assemblée générale',
  label_meetingDate: 'Date de l’assemblée',
  label_status: 'Statut',
  label_action: 'Action',
  label_advancedSearch: 'Advanced search',
  label_cet: ' CET',
  label_securityClass: 'Catégorie de titre',
  label_createGenMeeting: 'Create general meeting',
  label_editGenMeeting: 'Edit general meeting',
  label_requestSubmittedAt: 'Request submitted on',
  label_requestSubmittedBy: 'Request Submitted by',
  label_extendedIntermediaryDeadline: 'Intermediary deadline',
  label_extendedIntermediary: 'Extended Intermediary deadline',
  votecollector_list_tooltip:
    'Please click on group name(s) to enter the vote collector display names.',
  label_viewExtnReq: 'View extension requests',
  label_vc_group_success: 'Display name saved successfully for given group',
  label_vote_collector_group: 'Vote collector group',
  label_vc_alias_name_error:
    'Please ensure that display name in English language is entered',
  label_noDataMessageExt: 'There are no extension requests available',
  label_cancelExtnReq: 'Extension Request has been cancelled successfully',
  label_notAllowedExtnReq: 'Issuer does not allow (new) extension requests',
  label_new_end_date: 'New end date/time',
  label_downloadRegFormTemplate: 'Download registration form template',
  label_extReq: 'Extension request',
  label_approveExtnReq: 'Extension Request has been approved successfully',
  label_rejectExtensionReq: 'Extension Request has been rejected successfully',
  label_rejectAllExtnRequests:
    'Extension Requests have been rejected successfully',
  label_approveExtReqError:
    'Please enter a valid date. Maximum 5 hours of extension time is allowed and Extension Date cannot be greater than General Meeting Date',
  label_userMaintenance: 'User maintenance',
  label_auditAndTemplate: 'Audit and template',
  label_liveMeetingOverview: 'Live meetings overview',
  label_shareHolderEmail: 'Shareholder email',
  label_dwtEvents: 'DWT Events',
  label_entity: 'Entity',
  label_publicationDate: 'Publication date',
  label_registrationDeadline: 'Registration deadline',
  label_closingDate: 'Date limite',
  label_intermediaryDeadline: 'Intermediary deadline',
  label_options: 'Actions',
  label_typeAndLocation: 'Type and location',
  label_schedule: 'Horaires',
  label_shareClasses: 'Catégories d’action',
  label_agenda: 'Ordre du jour',
  label_agenda_number: 'Agenda number',
  label_features: 'Features',
  label_documents: 'Documents',
  label_inviteSharePlan: 'Invite share plan',
  label_forAgainstVotingInstructions: 'For/Against Voting instruction',
  label_abstainedVotingInstructions: 'Abstained Voting instruction',
  label_select: '- -  Sélectionner- -',
  label_meetingType: 'Meeting type',
  label_meetingName: 'Intitulé d’assemblée',
  label_name: 'Nom de famille',
  label_corporateWebsite: 'Corporate website',
  label_website: 'Site',
  label_location: 'Localité',
  label_locationName: 'Nom du lieu',
  label_locationOnTicket: 'Location on ticket',
  label_street: 'Rue',
  label_postalCode: 'Code postal',
  label_country: 'Pays',
  label_proxiesList: 'Clearing custodian',
  label_selectClearingIntermediary: '--Select clearing intermediary--',
  label_timeZone: 'Time zone',
  label_ibanNumber: 'IBAN number',
  label_directDebit: 'Direct debit',
  label_convocationDate: 'Convocation date',
  label_shareholderAccessDate: 'Shareholder access date',
  label_intermediaryAccessDate: 'Intermediary access date',
  label_recordDate: 'Date d’enregistrement',
  label_shareholderDeadlineDate: 'Date limite',
  label_intermediaryDeadlineDate: 'Intermediary deadline date',
  label_regListReleaseDate: 'Registration list release date',
  label_noOfSecurities: "Nombre d'actions",
  label_generalMeetingDate: 'Date de l ’assemblée générale',
  label_error_hoursAndMinutes:
    'Please enter hours from 0 to 23 and minutes from 0 to 59',
  label_prefixNumber: 'Prefix number',
  label_isin: 'ISIN',
  label_shareClassName: 'Share class name',
  label_intermediary: 'Intermédiaire',
  label_csd: 'Dépositaire central',
  label_shareholder: 'Shareholder',
  label_shareplanholder: 'Share plan holder',
  label_search: 'Search',
  label_additionalShareClass: 'Additional share class',
  label_additionalDocs: 'Additional documents',
  label_attachDocument: 'Attach document',
  label_browse: 'Naviguer',
  label_votable: 'Votable',
  label_agendaItem: 'Agenda item',
  label_agent: 'Agent ',
  label_addNewVoteCollector: 'Add new vote collector',
  label_addVoteCollector: 'Add vote collector',
  label_noDataFound: 'No data Found',
  label_login: 'Connexion',
  label_loginHeader: 'connexion',
  label_forgotPwrd: 'Mot de passe oublié',
  label_gmNotice: 'GM notice',
  label_optOutGmNotice: 'Désabonnement aux annonces AG',
  label_webaPage: 'Visible on relevant web pages',
  label_attendanceCard: 'Attendance card',
  label_previous: ' Previous',
  label_next: 'Suivant',
  label_prev: 'Précédent',
  label_first: 'Premier',
  label_last: 'Last',
  label_nl: 'NL',
  label_en: 'EN',
  label_fr: 'FR',
  label_pipe: ' | ',
  label_colonStar: ':*',
  label_colon: ':',
  label_hash: '#',
  label_comma: ',',
  label_questionMark: '?',
  label_noDataMessage: 'No data available',
  label_agents: 'Agents',
  label_issuers: 'Issuers',
  label_approve: 'Approve',
  label_reject: 'Reject',
  label_rejectAll: 'Reject all',
  label_accept: 'Accept',
  label_confirmationMessage: 'Message de confirmation',
  label_areYouSure: 'Are you sure you want to approve?',
  label_areYouSureSend: 'Are you sure you want to send?',
  label_areYouSureCancel: 'Are you sure you want to cancel?',
  label_areYouSureSave: 'Are you sure you want to save?',
  label_yes: 'Yes',
  label_no: 'No',
  label_save: 'Enregistrer',
  label_view: 'View',
  label_viewUsers: 'View users',
  label_edit: 'modifier',
  label_notification: 'Notification',
  label_notification_link: 'GM Notification Sheet',
  label_inviteShareplan: 'Invite share plan',
  label_add: 'Add ',
  label_city: 'Localité',
  label_logo: 'Logo',
  label_addNew: 'Add new',
  label_admin: 'Admin',
  label_phoneNumber: 'Numéro de téléphone',
  label_personalPhoneNumber: 'Personal phone number',
  label_ok: 'Ok',
  label_groupMail: 'Opt-out for emails related to General Meetings',
  label_optOutTaxReclaim: 'Opt-out for emails related to Tax reclaim events',
  label_cancel: 'Annuler',
  label_confirm: 'Confirmer',
  label_file: 'File',
  label_title: 'Civilité',
  label_firstName: 'Prénom usuel',
  label_lastName: 'Nom de famille ',
  label_meetingTypeAGM: 'AGM',
  label_meetingTypeEGM: 'EGM',
  label_goBack: 'retour',
  label_home: 'Accueil',
  label_generalMeeting: 'Assemblée générale',
  label_DWT: 'DWT',
  label_shareholderID: 'Shareholder ID',
  label_corporateBroking: 'Corporate Broking',
  label_btnPending: 'En attente',
  label_btnAccepted: 'Accepted',
  label_btnApproved: 'Approuvé',
  label_btnRejected: 'Rejeté',
  label_btnCancelled: 'Annulé',
  label_requestCreatedAt: 'Requête créée le',
  label_nameBeneficialOwner: 'Name beneficial owner',
  label_shareholderPosition: 'Shareholder position',
  label_option: 'Option',
  label_delete: 'Delete',
  label_generalDetails: 'Généralités ',
  label_registrations: 'Registrations',
  label_voteCollector: 'Collecteur de vote',
  label_locationOnCertificate: 'Location on certificate',
  label_votingResult: 'Voting result',
  label_votingAvailable: 'Voting available',
  label_votingNotAvailable: 'Voting not available',
  label_votingItem: 'Elément de vote',
  label_defaultVotingOptions: '(pour, contre, abstenu)',
  label_intermediaryWorkstation: 'Intermediary workstation',
  label_shareholderWorkstation: 'Shareholder workstation',
  label_SplitVotingRequired: 'Split voting required',
  label_disclosureOfUnderlyingBeneficialOwnersRequired:
    'Disclosure of underlying beneficial owners required',
  label_fillText:
    '* Fill out all fields in this page and click "Save" button to navigate to next section.',
  label_generalMeetingShouldBeVisible: 'General meeting should be visible',
  label_enableNewVotingResolution: 'Enable new voting resolution',
  label_agentLogoOnRegistrationTicket: 'Agent logo on registration ticket',
  label_issuerLogoOnRegistrationTicket: 'Issuer logo on registration ticket',
  label_emailAddress: 'Adresse courriel',
  genDetails_label2: 'Disclosure of underlying beneficial owners required',
  label_shareClass: 'Catégorie de titre',
  label_companyName: 'Nom de l’entreprise',
  label_company: 'Société',
  label_voteConfirmation: 'Confirmation de vote',
  label_votingInstructions: 'Instructions de vote',
  label_viewVotingInstructions: 'voir les instructions de vote',
  label_viewSharePlanHolder: 'View Share plan holder',
  label_viewattendanceRequests: 'View attendance requests',
  label_voteRightsPerShare: 'Nombre total de titres',
  label_entitledVoteRightsShares: 'Titres donnant droit au vote',
  label_shareplan: 'Share plan',
  label_votecollector: 'Collecteur de vote',
  label_resend_selected: 'Resend Selected',
  searchSharePlanHolder_label: 'Securities holder name/Email',
  label_resend: 'Resend',
  label_resendInviteSharePlan: 'Resend invite share plan',
  label_securities: 'securities',
  label_instruction: 'instruction',
  label_attendanceRegistration: 'Attendance registration',
  label_votingInstruction: 'Voting instruction',
  label_oldPwrd: 'Ancien mot de passe',
  label_newPwrd: 'Nouveau mot de passe',
  label_no_sharePlan_holders_available: 'No Share plan holders available',
  label_sharePlan_reinvite:
    'Invite share plan email will be sent to the selected share plan holders',
  label_reenterPwrd: 'Resaisir le nouveau mote de passe',
  label_changePwrd: 'Changement de mot de passe',
  label_submit: 'Submit',
  label_pwrdRequirements: 'Le mot de passe doit contenir ',
  label_total: 'Total',
  label_download: 'Download',
  label_registrationListPDF: 'Registration list PDF',
  label_registrationListXLS: 'Registration list XLS',
  label_shareholdersAttendanceRegistration:
    'Shareholders requested for attendance registration',
  label_shareholdersVotingInstructions:
    'Shareholders requested for voting instructions',
  label_reinviteShareplan: 'View/Reinvite share plan holder',
  'label_%ofOutstandingVotingRights': '% of outstanding voting rights',
  label_attendanceRequest: 'Demande à assister',
  label_noAction: 'No action',
  label_registrationTicketPreview: 'Registration ticket preview',
  label_downloadRegCertificate: 'Download all registration certificates',
  label_registrationCertificates: 'Registration certificates',
  label_createNewRegistration: 'Create new registration',
  label_downloadAll: 'Download all',
  label_uppercaseLetters: 'Des majuscules',
  label_lowercaseLetters: 'Des minuscules',
  label_numbers: 'Des chiffres',
  label_specialCharacters:
    "Des caractères spéciaux ($ + , : ; = ? @ # | ' . ^ * ) ( % ! - ~ } { ] [ _ / )",
  label_8ormoreCharacters: '8 caractères au moins',
  label_email: 'Courriel',
  label_addOtherLang: 'Add other language',
  label_important: 'Important',
  label_registrationTicketCreated: 'Registration ticket created',
  label_emailAuditTrail: 'Email Audit Trail',
  label_auditTrail: 'Audit Trail',
  label_templates: 'Templates',
  label_uploadTemplates: 'Upload templates',
  label_sentOutMails: 'Sent out mails',
  label_reset: 'Mise a zéro',
  label_securitiesHolder: 'Securities holder',
  label_registrationNumber: "Numéro d'enregistrement",
  label_proxyName: 'Nom du mandataire',
  label_noOfShares: 'Nombre d’actions',
  label_fromDate: 'From date',
  label_toDate: 'To date',
  label_intermediaries: 'Intermediaries',
  label_emailType: 'Email type',
  label_receiverEmailAddress: 'Receiver email address',
  label_subject: 'Subject',
  label_emailDetails: 'Email details',
  label_sentTo: 'Sent to',
  label_userName: 'User name',
  label_from: 'From',
  label_to: 'To',
  label_auditTrailDetails: 'Audit Trail Details',
  label_user: 'Utilisateur',
  label_platform: 'Platform',
  label_Date: 'Date',
  label_templateType: 'Template type',
  label_subbjectOrDescription: 'Subject/Description',
  label_descriptionDetails: 'Description details',
  label_template: 'Template',
  label_language: 'Language',
  label_modified: 'Modified',
  label_sender: 'Sender',
  label_documentType: 'Document type',
  label_dwtBulkUpload: 'DWT bulk upload',
  label_bulkUpload: 'Bulk upload',
  label_bulkUploadVI: 'Bulk upload voting instructions',
  label_users: 'Users',
  label_role: 'Role',
  label_event: 'Event',
  label_eventName: 'Event name',
  label_dwtEvent: 'DWT envent',
  label_votingInvitationWithLinkToMeetings:
    'Voting invitation with link to meetings',
  label_sentDate: 'Sent date',
  label_newIntermediary: 'New Intermediary',
  label_intermediaryName: 'Nom de l’intermédiaire',
  label_intermediaryPhone: "Numéro de téléphone de l'intermédiaire",
  label_intermediaryEmail: 'Intermediary email address',
  label_intermediaryContactName: 'Contactpersoon intermediair',
  label_intermediaryGroupName: 'Intermediary group name',
  label_bic: 'BIC',
  label_registrationForm: 'Formulaire d’inscription',
  label_processVotingInstructions: 'Process voting instructions',
  label_receiveMeetingNotification: 'Receive meeting notification via Swift',
  label_processRegRequest: 'Process registration request',
  label_visibleInShareholderWorkstation: 'Visible in Shareholder workstation',
  label_CanProcessOnlyAssignedShareclasses:
    'Can process only assigned share classes',
  label_processCustomTemplate:
    'Process custom template for RC email to shareholder',
  label_addIssuer: 'Add Issuer',
  label_permissionsForms: 'Permissions / Registration forms',
  label_validFrom: 'Valid from',
  label_validTo: 'Valid to',
  label_openBracket: '(',
  label_closeBracket: ')',
  label_shareholderType: 'Shareholder type',
  label_selectShareholderType: '--Select Shareholder type--',
  label_initials: 'Initiales des prénoms',
  label_isAdmin: 'Is Admin',
  label_searchResults: 'Search results for',
  label_resetInstructionsSent: 'Reset instruction sent',
  label_resetPwrd: 'Reset password',
  label_checkEmail: 'Check your email',
  label_canProceedVotingInstructions: 'Can process voting instructons',
  label_addIntermediary: 'Ajouter un intermédiaire',
  label_addNewProxy: 'Add new proxy',
  label_thankYou: 'Merci',
  forgotPassSuccess_label1:
    "La demande de modification de votre mot de passe a été traitée. Si le nom d'utilisateur que vous avez saisi existe, un e-mail contenant des instructions vous sera envoyé.",
  label_entityName: 'Entity name',
  label_actionType: 'Action type',
  label_sentDateGMT: 'Sent date and time',
  label_screenName: 'Screen name',
  label_templateName: 'Template name',
  label_workstation: 'Workstation',
  label_templateText: 'Template text',
  label_doNotPublish: 'Do not publish',
  genMeetRegistration_label1: 'Total number of outstanding voting rights',
  genDetails_label1:
    'Include meeting documents along with registration certificate',
  agent_home_welcomeText1: `Welcome to ABN AMRO's Corporate Broking Portal, where you can facilitate your general meetings and DWT processes.`,
  agent_home_welcomeText2: 'Please find below your ',
  label_actionList: 'action list.',
  createGenMeet_fillFormText: 'Fill form in',
  label_euroclear_shares: 'Euroclear shares',
  createGenMeet_draftSaveText: 'Draft automatically saved at',
  createGenMeet_shareClass_label1:
    'Total number of outstanding securities, entitled to vote',
  createGenMeet_shareClass_label10:
    'Total number of outstanding securities for Euroclear Nederland, entitled to vote',
  createGenMeet_shareClass_label2:
    'Vote rights per share(for this share class)',
  createGenMeet_shareClass_label3: 'Total number of voting rights',
  createGenMeet_shareClass_maxARAllowed:
    'Nombre maximal de demandes de présence autorisées',
  createGenMeet_vote_by_proxy: 'Votez par proxy',
  createGenMeet_vote_by_correspondence: 'Votez par correspondance',
  createGenMeet_shareClass_label5: 'Demande de présence',
  createGenMeet_shareClass_label5_1: 'Participation virtuelle',
  createGenMeet_shareClass_label6: 'Registration list available via',
  createGenMeet_shareClass_label7: 'Vote collector workstation',
  createGenMeet_shareClass_label8: 'Authorized vote collectors',
  createGenMeet_shareClass_label9: 'Émetteur',
  votingByCorrespondence: 'Vote par correspondance',
  virtualParticipation: 'Participation virtuelle',
  createGenMeet_shareClass_agent_itself: 'Agent',
  createGenMeet_agenda_label1: 'Explanation (if applicable)',
  createGenMeet_agenda_label2: 'New venture approval',
  createGenMeet_agenda_label3: 'Add agenda item',
  createGenMeet_agenda_copy: 'Copy agenda items',
  createGenMeet_agenda_copy_tooltip:
    'Copies all agenda items to any other selected language',
  createGenMeet_doc_label1: 'Attach file to',
  createGenMeet_feature_label1: 'Intermediary workstations',
  createGenMeet_feature_label2: 'Split voting allowed',
  createGenMeet_feature_rightToSpeak:
    'Attendance request with right to speak allowed',
  createGenMeet_feature_label3: 'Shareholder workstations',
  createGenMeet_feature_label5: 'Split voting allowed',
  createGenMeet_feature_label6: 'Securities account number disclosure required',
  createGenMeet_feature_linkedSC:
    'Display message to vote/attend for the share class linked meeting',
  linkedSC_feature_tooltip:
    "Enabling this feature will alert shareholders to vote/attend for the meeting which has shared share class(ISIN). The message will be displayed in shareholder work station only if the current date is with in the range of other meeting's shareholder access and deadline dates",
  linkedMeeting_attend_label: 'To also attend the ',
  linkedMeeting_vote_label: 'To also vote for the ',
  label_plsClick_Yes_toContinue: 'please click on "Yes" to continue',
  label_registrationTicket: 'Registration ticket',
  createGenMeet_feature_label8: 'Include barcode on registration ticket',
  createGenMeet_feature_label9: 'Agent logo on attendance card',
  createGenMeet_feature_label10: 'Issuer logo on attendance card',
  createGenMeet_feature_label11:
    'Include blank power of attorney on registration ticket',
  createGenMeet_feature_label12:
    'Include meeting document along with registration certificate',
  virtualParticipation_allowed: 'Virtual participation allowed',
  only_9numbers_error: 'Please enter numeric value of length 9 characters only',
  virtual_participation_provider_meeting_id: 'Meeting ID',
  virtual_participation_provider_info1:
    '- It should be 9 numerics without any special characters. example: 127909002.',
  virtual_participation_provider_info2:
    '- Please share the meeting id and the issuer details with the virtual participation provider once the meeting is successfully created.',
  createGenMeet_InviteSP_label1: 'From address',
  label_toAddress: 'To address',
  createGenMeet_InviteSP_label2: 'Email subject',
  createGenMeet_InviteSP_label3: 'Email body',
  createGenMeet_InviteSP_label4: ' Include uploaded files',
  createGenMeet_InviteSP_label5: 'No Files uploaded',
  createGenMeet_InviteSP_label6: 'Please confirm your registration',
  createGenMeet_InviteSP_label7: 'Step 1 ',
  createGenMeet_InviteSP_label8: 'Step 2 ',
  createGenMeet_InviteSP_download: 'Download Share Plan template',
  createGenMeet_InviteSP_upload: 'Upload Share Plan data',
  createGenMeet_InviteSP_placeholder1: 'corporate.broking.finance_dummy@nl.com',
  createGenMeet_InviteSP_placeholder2: 'Please confirm your registration',
  createGenMeet_InviteSP_tooltip1:
    'Template needs to be filled in with share plan data by issuer.',
  createGenMeet_InviteSP_tooltip2:
    'Template downloaded earlier needs to be uploaded with share plan data.',
  userMaint_voteCollector_label: 'Collecteur de vote',
  userMaint_intermediary_label: 'Intermédiaire',
  userMaint_proxy_label: 'Proxy Intermediary',
  userMaint_groupDetails_label: 'Group details',
  userMaint_userAccounts_label: 'User accounts',
  userMaint_groupEmailAddress_label: 'Adresse courriel groupe',
  userMaint_bankIdentifierCode_label: 'Code d’identification banque ',
  userMaint_bankAccountNumber_label: 'Bank account number',
  userMaint_usersList_label: 'Users list',
  userMaint_officePhone_label: 'Office phone',
  userMaint_commentsForRejection_label: 'Comments for rejection',
  userMaint_issuer_warning_alreadyvc_label:
    'The provided e-mail is already registered as Vote Collector. Are you sure you want to add it as Issuer?',
  addUser_userAccounts_label: 'User account',
  forgotPass_label1: 'Demande de nouvel mot de passe envoyée',
  forgotPass_label2:
    'Have you lost your password? You can request a new password. We will e-mail an activation link, and you can then enter a new password.',
  viewAttendance_label1:
    'Share holder name/Registration number/Securities account number',
  viewAttendance_label2: 'Shareholders registered list',
  viewVotingInstructions_label1: 'Securities holders registered list',
  item0: 'Accueil',
  item1: 'Assemblées générales',
  item3: 'DWT',
  item4: 'Shareholder ID',
  item5: 'Corporate Broking',
  item2: 'Mon compte',
  button0: 'Draft',
  button1: 'Pending',
  button2: 'To be published',
  button3: 'Live',
  button4: 'Completed',
  button5: 'Annulé',
  extnbtn0: 'Pending',
  extnbtn1: 'Accepted',
  extnbtn2: 'Rejected',
  label_votingConfirmation: 'Voting confirmation',
  label_votingInstructionReport: 'Voting instruction reports',
  label_downloadPDF: 'Download PDF',
  label_downloadExcel: 'Download Excel',
  label_summary: 'Summary',
  label_myVotes: 'Mes votes/mes inscriptions',
  label_Details: 'Détails',
  label_inFavor: 'In favor',
  label_against: 'Contre',
  label_Abstained: 'Abstenu',
  label_addAttachments: 'Add attachments',
  label_addText: 'Add text',
  label_send: 'Envoyer',
  adminAgent_label_profile: 'Données personnelles',
  adminAgent_label_changePwrd: 'Changement de mot de passe',
  adminAgent_label_initials: 'Initiales des prénoms',
  adminAgent_label_middleName: 'Deuxième prénom',
  adminAgent_label_phoneNumber: 'Numéro de téléphone',
  adminAgent_label_pwrdRequirements: 'Le mot de passe doit contenir ',
  adminAgent_label_confirmation: 'Confirmation message',
  adminAgent_label_wantToSave: 'Are you sure you want to save?',
  gmNotification_label_1: 'Notification overview',
  gmNotification_label_2: 'Attachments to notification',
  gmNotification_label_3: 'Customise message',
  gmNotification_label_4: 'Meeting notice',
  gmNotification_label_5: 'Recipient list',
  label_recipientAddress: 'Recipient address',
  gmNotification_custMsg1: 'Available means of participation',
  gmNotification_custMsg2:
    'Additional information for notification of participation',
  gmNotification_custMsg3: 'Narrative',
  gmNotification_custMsg4:
    'Other requirements for participation (if applicable)',
  voting_result: 'Voting results',
  select_recipient: '--Select recipient--',
  select_shareClass: '--Sélectionner une catégorie d’action--',
  label_selectProxyType: '- - Sélectionner le type de procuration - -',
  label_selectCountry: ' - -  Sélectionner pays - -',
  label_selectUserType: '- - Sélectionner le type d’utilisateur - -',
  label_selectTitle: '- - Sélectionner la civilité- -',
  label_selectIntermediary: '- - Sélectionner un intermédiaire--',
  gmGeneralDetails_label: 'Shareholder',
  label_serialNo: 'Sl No.',
  label_error_lastNameReq: 'Last name is required.',
  label_error_lastNameWrong: 'Le nom de famille est incorrect',
  label_error_officeNumReq: 'Office phone number is required.',
  label_error_officeNumWrong: 'Le numéro de téléphone du bureau est erroné',
  label_error_phoneNumReq: 'Phone number is required',
  label_error_persoNumWrong: 'Personal phone number is wrong',
  label_error_phoneNumWrong:
    'Numéro de téléphone trop court ou format invalide',
  label_error_emailReq: 'L’adresse courriel est obligatoire',
  label_error_emailWrong:
    'L’adresse courriel est trop courte ou ne correspond pas au format',
  label_error_name: 'Le nom est obligatoire',
  label_error_nameWrong: 'Le nom est incorrect',
  label_error_postWrong: 'Le code postal est incorrect',
  label_error_cityReq: 'La localité est obligatoire',
  label_error_cityWrong: 'La localité est incorrecte',
  label_error_noOfSharesReq: 'Le nombre d’actions est obligatoire  ',
  label_error_noOfSharesWrong: 'Le nombre d’actions est incorrect',
  label_error_selectedQuantityNoOfSharesReq:
    'La participation partielle est obligatoire ',
  label_error_selectedQuantityNoOfSharesWrong:
    'La participation partielle est incorrecte',
  label_error_addressWrong: 'L’adresse est incorrecte',
  label_error_securityAccNoReq: 'Le numéro de compte titres est obligatoire',
  label_error_securityAccNoWrong:
    'Lors de la saisie le numéro de compte titres, vous ne pouvez utiliser que des chiffres et des lettres',
  label_error_countryReq: 'Country is required.',
  label_error_countryWrong: 'Country is wrong.',
  label_error_bicReq: 'Bank Identifier Code is required.',
  label_error_bicWrong: 'Le code d’identification de la banque est incorrect',
  label_error_legalIndentifierWrong: 'Legal entity identifier is wrong.',
  label_error_repNameWrong: 'Representative name is wrong.',
  label_error_proxyNameWrong: 'Proxy name is wrong.',
  label_error_proxyNameReq: 'Le nom du mandataire est obligatoire',
  label_poaForm: 'PoA form',
  linkIntermediary_header1:
    'Intermediaries for Institutional Shareholder Services (ISS) Europe',
  linkIntermediary_header2:
    'These are the intermediaries for which Institutional Shareholder Services (ISS) Europe can process regulations',
  linkIntermediary_button1: 'Link to Intermediary group',
  addPermission_header: 'Permissions/Registration form',
  label_error_middleNameWrong: 'Middle name is wrong.',
  label_error_firstNameWrong: 'First name is wrong.',
  label_error_initialsWrong: 'Les initiales de prénom sont incorrectes',
  label_error_titleWrong: 'Title is wrong.',
  label_error_titleReq: 'Title is required.',
  voteCollectorbutton0: 'Before deadline',
  voteCollectorbutton1: 'After deadline',
  voteCollectorbutton2: 'Completed',
  label_userType: 'User type',
  label_address: 'Addresse',
  label_dob: 'Date de naissance',
  label_securityAccountNumber: 'Compte titres',
  label_numberOfShares: 'Nombre d’actions',
  label_attendByProxy: 'Présence par mandataire',
  label_createNewProxy: 'Créer un nouveau mandataire',
  label_editProxy: 'Modifier le mandataire',
  label_selectProxy: 'Sélectionner un mandataire',
  label_delegateToSaat: 'Délégué à SAAT',
  label_attendOrBlankVoteLine1: 'Assister à une réunion en personne',
  label_attendOrBlankVoteLine2: 'ou donnez une instruction vide à SAAT',
  label_proxyType: 'Type de mandataire',
  label_proxyId: 'Proxy id',
  label_none: 'aucune',
  label_vote: 'Donner des instructions de votes',
  label_register: 'Inscription nouvel utilisateur',
  label_allFor: 'Tout en faveur',
  label_splitVotes: 'Split votes',
  label_entireHolding: 'Ensemble des titres',
  label_selectedQuantity: 'Selected quantity',
  label_partialHolding: 'Part des titres',
  label_for: 'Pour',
  label_enterNumberOfShares: 'Nombre d’actions',
  label_allAgainst: 'Tout contre',
  label_allAbstained: 'Tout abstenu',
  label_attend: 'Assister à une réunion en personne',
  label_downloadCertificate: 'Download certificate',
  label_upComingMeetings: 'Assemblées générales à venir',
  label_representativeName: 'Nom du représentant',
  label_legalEntityIdentifier: 'Identifiant de l’entité',
  label_termsAndConditions: 'Conditions',
  label_terms: 'conditions',
  label_termsUse: 'Conditions d’utilisation',
  label_numberOfSecurities: 'Number of securities',
  label_attendanceInfo:
    'The following registrations will be automatically cancelled when attendance request is submitted',
  label_votingInfo:
    'The following registrations will be automatically cancelled when voting instruction is submitted',
  label_termsSentence: 'J’ai lu et j’accepte les ',
  label_belgiumTermsConditions1:
    'Je/nous reconnais/reconnaissons avoir pris connaissance des ',
  label_belgiumTermsConditions2:
    ", d'en saisir le contenu, d'accepter le contenu et d'avoir conservé, imprimé ou me/nous envoyé une copie. ",
  label_votingViaIMWorkStation:
    'Voting instructions via the intermediary workstation',
  label_votingViaSHWorkStation:
    'Voting instructions via the shareholder workstation',
  label_requestRegistrationViaSHWorkStation:
    'Request a registration ticket via the shareholder workstation',
  label_groupName: 'Group name',
  label_groupCountry: 'Group country',
  label_groupRegisteredOffice: 'Group registered office',
  label_internationalBankAccountNumber: 'International bank account number',
  label_taxDeclarationNumber: 'Tax declaration number',
  label_uploadedDocuments: 'Uploaded documents',
  label_groupStatus: 'Group status',
  label_groupCity: 'Group city',
  notification_text:
    'Cher/Chère actionnaire, afin d’être assuré(e) du meilleur service, veuillez contrôler si vos coordonnées sont à jour.',
  label_companiesOfferingGm:
    'Les sociétés utilisant le portail actionnaires d’ABN AMRO sont:',
  label_seeMore: 'Voir plus',
  label_knowMore: 'Know more',
  label_error_groupName: 'Group name is wrong',
  label_error_groupNameReq: 'Group name is required',
  label_pleaseFillThisField: 'Please fill this field',
  label_proxyProvided: 'Proxy provided',
  label_proxyProfile: 'Proxy profile',
  label_noDataIssuerMessage: 'No data available for issuer',
  label_feedback: 'Commentaire',
  label_extensionRequestFor: 'Extension Request for',
  label_shareHolderName: 'Shareholder name',
  label_extendDeadlineTillDate: 'Extend deadline till date',
  label_reasonForDelay: 'Reason for delay',
  label_reasonForReject: 'Reason for rejection',
  label_time: 'Time',
  label_createExtnReq: 'Create extension request',
  label_tillDateReq: 'Extend deadline till date is required',
  label_shareholderNameReq: 'Shareholder name is required',
  label_shareholderPosReq: 'Shareholder position is required',
  label_shareholderPosError: 'Shareholder position is wrong',
  label_shareholderPosIncorrect: 'Shareholder position can be 1 to 999999999',
  label_attachment: 'Attachment',
  label_importFile: 'Import file',
  label_downloadStandardFile: 'Download standard file',
  label_bulkUploadAttendanceRequest: 'Bulk upload attendance request',
  label_MR: 'Mr.',
  label_MS: 'Ms.',
  label_voting_instruction: 'Instructions de vote',
  label_attendance_requests: 'Demandes de présence',
  label_invite_share_plan: 'Invite share plan',
  label_shareholder_email: 'Shareholder email',
  label_intermediary_email: 'Intermediary email',
  label_notification_overview: 'Notification Overview',
  label_create_extension_request: 'Create Extension Request',
  label_bulk_upload_attendance_requests: 'Bulk upload attendance request',
  label_bulk_upload_voting_instructions: 'Bulk upload voting instructions',
  label_view_attendance_requests: 'View attendance requests',
  label_view_agent_group: 'View agent group',
  label_edit_agent_group: 'Edit agent group',
  label_edit_issuer_details: 'Edit issuer details',
  label_edit_intermediary_details: 'Edit intermediary details',
  label_edit_proxy_intermediary_details: 'Edit proxy intermediary details',
  label_edit_votecollector_details: 'Edit vote collector details',
  label_agent_group: 'Agent users',
  label_view_agent_group_users: 'View agent users',
  label_add_user: 'Add user',
  label_add_issuer: 'Add issuer',
  label_add_intermediary: 'Ajouter un intermédiaire',
  label_add_proxy_user: 'Add proxy user',
  label_add_vote_collector: 'Add vote collector',
  label_view_agent_details: 'View agent details',
  label_edit_agent_details: 'Edit user details',
  label_edit_vote_collector_details: 'Edit vote collector details',
  label_view_issuer_group: 'Issuer groups',
  label_view_vote_collector_group: 'Vote collector groups',
  label_view_intermediary_group: 'Intermediary groups',
  label_view_proxy_intermediary_group: 'Proxy intermediary groups',
  label_add_issuer_group: 'Add issuer group',
  label_add_vote_collector_group: 'Add vote collector group',
  label_add_intermediary_group: 'Add intermediary group',
  label_add_proxy_intermediary_group: 'Add proxy intermediary group',
  label_view_intermediary_user: 'View intermediary users',
  label_view_proxy_intermediary_user: 'View proxy intermediary users',
  label_view_issuer_user: 'View issuer users',
  label_view_vote_collector_user: 'View vote collector users',
  label_view_issuer_details: 'View issuer details',
  label_view_intermediary_details: 'View intermediary details',
  label_view_proxy_intermediary_details: 'View proxy intermediary details',
  label_view_vote_collector_details: 'View vote collector details',
  label_add_permissions: 'Add permissions',
  label_link_intermediaries: 'Linked intermediaries',
  label_link_intermediary: 'Link to Intermediary',
  label_linkedIntMessage:
    'If needed intermediary is not listed, please contact Agent to onboard in the system.',
  label_linkedIntError:
    'The PoA for the selected intermediary has expired or is not yet valid, please contact Agent.',
  label_page_title: 'Corporate Broking Portal',
  label_logoUpdate: 'Update logo',
  label_extededTill: 'Intermediary deadline extended till',
  label_reasonForExtension: 'Reason for extension',
  label_privacy_policy: 'Politique sur la vie privée et les cookies',
  label_privacy: 'Vie Privée',
  label_cookies: 'Cookies',
  label_contact_details: 'Coordonnées de contact',
  label_numberOfSharesRequired: 'Le nombre d’actions est obligatoire  ',
  label_numberOfSharesWrong:
    'Le nombre des actions doit être en chiffres et ne pas commencer par zéro',
  label_addProxy:
    'Number of shares should be numbers and should not start with zero',
  label_mandatoryFieldError: 'Veuillez remplir les champs obligatoires',
  label_textRequired: 'Veuillez remplir les champs obligatoires',
  label_pwrdReq: 'Mot de passe requis',
  newPwd_oldPwdError: 'Le nouveau mot de passe ne peut être  égal à l’ancien.',
  checkPattern_Error: 'S’il vous plaît vérifier le motif',
  label_noProxyAvailable: 'Veuillez vérifier vérifier le format',
  label_close: 'Fermer',
  label_feedbackType: 'Type de commentaire ',
  label_feedbackComment: 'Commentaires',
  label_feedback_question: 'Question',
  label_feedback_opinion: 'remarque',
  label_feedback_comment_required: 'Feedback comment is required.',
  label_feedback_comment_maxlength: 'Max length is 4000 characters.',
  label_reason_comment_required: 'Reason comment is required.',
  label_reason_comment_maxlength: 'Max length is 2000 characters.',
  label_phone: 'Téléphone',
  label_show_details: 'Afficher les détails',
  label_hide_details: 'Cacher les détails',
  thankYou_msg: 'Your vote is important.Thank you for your voting instruction.',
  thankYou_msg1:
    'If you wish to change your vote, you can do this upto shareholder deadline date of the general meeting.',
  thankYou_msgButton1: 'Vote for other Share classes for this GM',
  thankYou_msgButton2: 'Browse and vote for GMs from other Issuers',
  notificationModal_header: 'Save Customised Message',
  notificationModal_body:
    'Do you want to retain the modified text for future use? (applies to all Issuers)',
  notificationModal_rightButton: 'No,for this instance only',
  notification_successMsg1: 'Message saved successfully for future use',
  notification_successMsg2: 'Message saved successfully for only this instance',
  notification_successMsg3: 'Notification sent successfully',
  label_shareHolderCreated:
    'Votre inscription a été complétée. Vérifiez votre email pour activer votre compte.',
  label_shareHolderCreated_header: 'Inscription complétée',
  label_shareholderCreationError:
    'Some error has occured while creating Shareholder, Please contact system administration',
  label_agendaDeleted: 'Agenda deleted successfully.',
  label_agendaItemsCopied:
    'Agenda items successfully copied to other language(s).',
  label_documentDeleted: 'Document deleted successfully.',
  label_shareClassDeleted: 'Share class deleted successfully.',
  label_messageSaved: 'Meeting saved successfully.',
  label_createMeetingError:
    'Something went wrong while creating meeting. Please try after sometime',
  label_fillMandatoryFields:
    'Please fill mandatory fields to save this section',
  label_fillValidInput:
    'Please fill mandatory fields with valid input and try again.',
  label_attachmentRequired:
    'Attachment cannot be blank, Please upload a pdf file',
  label_documentLessThan5mb: 'Document should be less than 5mb',
  label_meetingSentToIssuer: 'Meeting sent to Issuer',
  label_meetingApproved: 'Meeting approved successfully',
  label_agendaRequired: 'Please fill agenda section',
  label_docRequired: 'Please fill document section.',
  label_shareClassRequired: 'Please fill share class section.',
  label_create_extn_error:
    'Extend deadline till date must be greater than intermediary deadline date and less tha general meeting date.',
  label_modalConfirmAction: 'Confirm action',
  label_modalVotingInsCreatErr: 'Voting is not available',
  label_modalVotingInsCreatErrMsg:
    'Voting instruction cannot be created for this General Meeting as the intermediary deadline date is already crossed',
  label_modalBulkUploadCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as voting instruction creation is not enabled',
  label_modalBulkUploadAttendCreatErrMsg:
    'Bulk upload or upload BO details is not possible for this General Meeting as attendance request creation is not enabled',
  label_modalShareVotingInsCreatErrMsg:
    'La fonction instruction de vote n’est pas activée pour cette assemblée',
  label_modalShareAttenReqCreatErrMsg:
    'La fonction de demande de présence n’est pas activée pour cette assemblée',
  label_modalAttReqCreatErr: 'La demande de présence n’est pas disponible',
  label_modalAttReqCreatErrMsg:
    'You cannot create attendance request for this General Meeting as the intermediary deadline date is already crossed',
  label_modalVirtualReqCreateErr: 'Cannot create virtual participation',
  label_modalVirtualReqNoEntitlements:
    'Not entitlements found for this meeting',
  label_modalVirtualReqErrMsg:
    'You cannot update virtual participation request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCreatErrMsg:
    'You cannot create/edit right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalRtsCancelErrMsg:
    'You cannot cancel right to speak request for this General Meeting since the intermediary deadline date is already expired',
  label_modalDelete: 'Delete message',
  label_modalConfirmDelTemplate:
    'Are you sure you want to delete this template?',
  label_modalUpdateTemp: 'Update template',
  label_modalConfirmUpdateTemplate:
    'Are you sure you want to update this template?',
  label_shareHolderRegistration: 'Inscription actionnaire',
  label_registrationCertificate: 'certificat d’inscription',
  label_request_type: 'Type de demande',
  lable_newReenterError: 'Mots de passe différents',
  issuer_groupUpdated_success: 'Issuer group details updated successfully',
  label_votingInsCreatErr: 'Information',
  label_bulkUploadCreatErr: 'Upload error',
  error_shareClass_not_created:
    'Something went wrong while saving share class. Please try after sometime.',
  error_shareClass_not_created_invalid_data:
    'Please fill mandatory fields with valid information',
  error_shareClass_not_created_invalid_participation_data:
      'Invalid Participation method selection, Please fill valid information',
  only_pdf_allowed: 'Invalid document(upload valid pdf file).',
  agenda_file_sizeMsg: 'This agenda file size is',
  file_sizeMsg: ' MB. Please upload a file less than 5 MB.',
  document_file_sizeMsg: 'This document size is',
  error_send_to_issuer:
    'There are errors. Please verify if all the sections are filled with valid information',
  newPwd_requiredError: 'New password required',
  reEnter_requiredError: 'Re-enter password required',
  numAlpha_error: 'Only alphabets and numbers are allowed',
  password_renterMatchError:
    'Both New password and Re enter password must be same',
  label_bulkUploadBO: 'Upload BO Details',
  label_registration_no: "Numéro d'enregistrement",
  label__error_registrationNoWrong: 'Registration Number is wrong',
  label__error_registrationNoReq: 'Registration Number is required',
  deadLineVoteModal_header: 'Voting is not available',
  deadLineModal_body:
    'Vous ne pouvez pas vous inscrire à cette réunion car la date limite est dépassée',
  deadLineModalVI_body:
    "Vous ne pouvez pas soumettre d'instructions de vote pour cette réunion car la date limite est dépassée.",
  deadLineAttendModal_header: 'Attendance request not available',
  lable_nonVotableItem: 'Non-votable item',
  share_class_error_checkbox_not_selected:
    'At least one option among Voting instructions or Request an attendance card must be selected.',
  document_checkbox_not_selected:
    'Please check atleast one checkbox - Attach the file to',
  noOfShares_error1:
    'Split votes should not be greater than Total Number of Shares',
  noOfShares_error2:
    'Sum of Split votes should be equal to Total Number of Shares',
  noOfShares_error3:
    'Partial quantity cannot be greater than Total No of shares',
  votingInstruction_error_msg:
    "Veuillez voter pour tous les points à  l'ordre du jour",
  'intermediary-success-msg': 'Intermediary request created successfully',
  reject_meetingErrorr:
    'Something went wrong while rejecting meeting. Please try after sometime',
  approve_meetingErrorr:
    'Something went wrong while approving meeting. Please try after sometime',
  reject_meeting: 'Meeting rejected successfully.',
  approve_meeting: 'Meeting approved successfully.',
  label_loginOrRegister: 'Login or Register',
  label_downloadAttachment: 'Download attachment',
  label_selectActionType: '--Select action type--',
  label_fields: 'Fields',
  label_beforeValue: 'Before value',
  label_afterValue: 'After value',
  label_forWord: 'For',
  label_selectTemplateType: '--Select template type--',
  label_selectTemplateName: '--Select template Name--',
  label_selectWorkstation: '--Select Workstation--',
  label_selectIssuer: '--Select issuer--',
  label_selectScreenName: '--Select screen name--',
  label_saveSuccessfullyStatus: 'Invite share plan sent successfully',
  label_errorImgFile: 'Please upload an image file.',
  label_errorFileSize: 'Please upload a file less than 250KB.',
  pwdChanged_successMsg: 'Password changed successfully',
  extnReq_cannotCreate: 'Extension request cannot be created for this meeting',
  vec_initiationMsg:
    'Processing confirmation, an e-mail will be sent when finished',
  selectDate_error: 'Please select date fields',
  fileSize_1mbError: 'Please upload a pdf file (Max Size 1 MB)',
  selectToDate_error: 'Please select To date which is greater than From date',
  selectRegForm_error: 'Please select Registration Form',
  label_areYouSureModal: 'Are you sure you want to ',
  label_thisRecord: ' this record?',
  label_shareHolder_registered: 'Shareholders registered by ',
  label_for_shareholder: 'for',
  label_toggle: 'Toggle navigation',
  uploadFile_error: 'Please upload a file.',
  fileSizeIs_error: 'This file size is',
  confrim_delink: 'Confirm de-link.',
  delink_confirmMsg: 'Are you sure you want to de-link this intermediary?',
  label_thisGroup: ' this group?',
  label_thisUser: ' this user?',
  label_enterUserNameToPasswordReset:
    'Veuillez saisir votre adresse e-mail pour demander un nouveau mot de passe',
  label_enterValidCredentials:
    "Veuillez entrer des informations d'identification valides",
  label_emailEmptyError: 'Please fill in email body',
  label_acceptExtnReqConfirm:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReqConfirm:
    'This request was approved before, are you sure you want to reject it?',
  label_acceptExtnReq:
    'This request was rejected before, are you sure you want to approve it?',
  label_rejectExtnReq:
    'This request was approved before, are you sure you want to reject it?',
  label_viewEditExtnReq: 'View/Edit Extension request',
  label_section_error: 'There are errors for ',
  label_section_language: 'language(s)',
  areSureDelete_modal: 'Are you sure you want to delete this Meeting?',
  areSureCancel_modal: 'Are you sure you want to cancel this Meeting?',
  areSureReject_modal: 'Are you sure you want to reject this Meeting?',
  createGenMeet_IntermediaryTooltip:
    'Select an intermediary,ADR or transfer agent which is only responsible to process voting / registration to attend instructions for this share class. Please select all intermediaries if this is not applicable.',
  issuer_name_req: 'Issuer name is required.',
  fill_issuer: 'Please fill out Issuer name.',
  meeting_type_req: 'Meeting type is required.',
  fill_meetingType: 'Please fill out meeting type.',
  meeting_name_req: 'Meeting name is required.',
  invalid_meetingName: 'Meeting name field accepts alphabets and numbers.',
  fill_meetingName: 'Please fill out meeting name.',
  website_req: 'Corporate website is required.',
  website_wrong: 'Corporate website is wrong.',
  fill_website: 'Please fill out corporate website.',
  eng: 'English',
  dut: 'Dutch',
  fre: 'French',
  ger: 'German',
  esp: 'Spanish',
  location_req: 'Location name is required.',
  location_wrong: 'Location name is wrong.',
  fill_location: 'Please fill out location name.',
  loc_tckt_req: 'Location on ticket is required.',
  loc_tckt_wrong: 'Location on ticket is wrong.',
  fill_loc_tckt: 'Please fill out location on ticket.',
  street_req: 'Street is required.',
  fill_street: 'Please fill out street.',
  postal_req: 'Postal code is required.',
  postal_wrong: 'Le code postal est incorrect',
  fill_postal: 'Please fill out postal code.',
  city_req: 'La localité est obligatoire',
  fill_city: 'Please fill out city.',
  country_req: 'Country name is required.',
  country_fill: 'Please fill out country name.',
  label_selectProxyName: '--Select proxy name--',
  createGenMeet_TimePickerTooltip:
    'With the cursor in hour or minute fields, you can use up/down arrow keys to increase/decrease the time.',
  label_callIntermediary: 'Appeler l`intermédiaire',
  label_lei: 'LEI',
  label_error_leiWrong: 'LEI is wrong',
  uploadPositionStatementFromIntermediary:
    'Téléchargez l’état de position de l’intermédiaire',
  intermediaryAcceptVoteCheckbox1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder, and represent and warrant his true identity.',
  intermediaryAcceptVoteCheckbox2:
    'We hereby confirm that the unique identification number is the same as the number provided to us by the aforementioned Securities Holder.',
  intermediaryAcceptVoteCheckbox3:
    'We hereby confirm that the aforementioned Securities Holder holds the mentioned Securities of the Issuing Institution at the record date.',
  intermediaryAcceptVoteCheckbox4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted by the aforementioned Securities Holder in connection with this General Meeting and that we nor the aforementioned Securities Holder will submit voting instructions other than those being submitted via the Website.',
  intermediaryAcceptVoteCheckbox5:
    'We acknowledge that we have read, understood and consented to the ',
  intermediaryAcceptVoteCheckbox6:
    ', as well as having saved, printed or sent ourselves a copy thereof.',
  label_viewMore: 'Voir plus',
  label_addressReq: "l'adresse est obligatoire",
  label_initialsReq: 'Les initiales de prénom sont obligatoires',
  bulkUpload_processMsg:
    'File is being processed. Please wait while processing the request.',
  bulkUpload_successMsg: 'File processed successfully.',
  bulkUpload_errorMsg: 'There are some errors',
  label_sessionLoggedOut:
    'You have not used the portal for 20 minutes and have been logged out automatically. Please click outside this message window to dismiss it.',
  label_sessionWarning:
    'Session is about to expire in 5 minutes. Please respond to avoid logging out',
  label_warning: 'Warning',
  label_gmActionList: 'General Meetings – Action List',
  label_dwtActionList: 'Tax Reclaim – Action List',
  label_decline: 'Decline',
  areSureAccept_modal: 'Are you sure you want to accept this Meeting?',
  areSureDecline_modal:
    'Are you sure you want to decline the role of vote collector for this meeting?',
  label_acceptedVc: 'accepted',
  label_declinedVc: 'declined',
  label_youHave: 'You have ',
  label_roleOfVC: ' the role of vote collector for meeting ',
  label_ofIssuer: ' of Issuer ',
  label_youCanAttend: 'You can vote/attend from ',
  label_dealineDateError: 'You cannot vote for this event – deadline date was ',
  label_registerText: 'Vous n’avez pas encore de compte utilisateur?',
  label_createAccountButton: 'Créer un compte utilisateut',
  areSureAccept_modalVc:
    'Are you sure you want to accept the role of vote collector for this meeting?',
  label_requestNumber: 'Request#',
  label_groupAddSuccess: 'New group added successfully',
  label_groupEditSuccess: 'User group updated successfully',
  label_on: 'sur',
  label_uploadPosStatement_fromInt:
    'Upload Position statement from Intermediary',
  label_logout: 'Log out',
  label_intPhoneNumber: 'Intermediary phone number',
  phoneNumber_error: "Le numéro de téléphone n'est pas valide",
  confirmFor_sendToIssuer:
    'Are you sure you want to send this meeting to issuer?',
  confirmUser_editDetails: 'Are you sure you want to edit user details?',
  confirmGroup_editDetails: 'Are you sure you want to edit this group details?',
  confirmApprove_Gm:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting.',
  confirmApprove_GmOtherLang:
    'Please confirm that you have checked all information and the Registration ticked preview (on the Registrations tab) for this meeting in all available languages.',
  pdfFile_formatError: 'Please upload document in PDF file format',
  label_createNewRequest: 'Create new request',
  label_cancelRequestBodyModal:
    'Êtes-vous sûr de vouloir annuler la demande soumise?',
  label_cancelRequestHeaderModal: "Demande d'annulation",
  label_viCancellationText1:
    'Vous demandez l`annulation d`instructions de vote approuvées. Une fois que vous confirmez, s`il vous plaît assurez-vous de contacter votre intermédiaire pour traiter la demande d`annulation.',
  label_intermediaryDepartment: 'Intermediary Department',
  label_loginId: 'Adresse e-mail',
  label_password: 'Mot de passe',
  label_searchByIssuer: 'Recherche par émetteur',
  label_DobError: 'Date of birth should not be future date',
  label_registerButton: 'S’inscrire',
  label_noOfSharesError: 'Please enter number of shares.',
  label_viewIssuers: 'View issuers',
  label_viewIntermediaries: 'View intermediaries',
  label_viewProxies: 'View proxies',
  label_viewVCs: 'View vote collectors',
  label_viewAgentUser: 'View agent user',
  label_viewIntermediaryUser: 'View intermediary user',
  label_viewProxyUser: 'View proxy intermediary user',
  label_viewIssuerUser: 'View issuer user',
  label_viewVCUser: 'View vote collector user',
  label_addAgentUser: 'Add agent user',
  label_addIntermediaryUser: 'Add intermediary user',
  label_addProxyUser: 'Add proxy intermediary user',
  label_addIssuerUser: 'Add issuer user',
  label_addVCUser: 'Add vote collector user',
  label_confirmVIRequest_header: "Confirmer la demande d'instruction de vote",
  label_confirmVI_registration_header: 'Confirmez votre inscription',
  areYouSure_confrimVI_message:
    'Are you sure you want to confirm voting instruction request?',
  label_confirmAttendRequest_header: 'Confirmer la demande de participation',
  areYouSure_confrimAttend_message:
    'Are you sure you want to confirm attendance request?',
  callIntermediary_SorryModalbody1:
    "Désolé, votre intermédiaire n'a pas encore fourni ses coordonnées. Veuillez contacter ABN AMRO Corporate Broking via ",
  callIntermediary_SorryModalbody2:
    ' ou corporate.broking@nl.abnamro.com, afin que nous puissions vous aider à contacter le service compétent de votre intermédiaire.',
  label_callIntermediary_VI_registration:
    'Merci pour votre instruction de vote. Vous devez maintenant vous enregistrer via votre intermédiaire. ',
  label_callIntermediary_AR_registration:
    "Maintenant que vous avez demandé à assister à la réunion, vous devez vous enregistrer par l'intermédiaire de votre intermédiaire.",
  label_ask_provideIdentification_VI:
    'Vous êtes priés de contacter votre intermédiaire et de confirmer le numéro d’identification suivant: ',
  label_sh_vi_modalBody2:
    'Il peut arriver que votre intermédiaire ne soit pas familier avec ce numéro. Dans ce cas, votre intermédiaire peut contacter ABN AMRO (AVA@nl.abnamro.com).',
  label_sh_vi_modalBody3:
    'Si vous avez d’autres questions, n’hésitez pas á contacter ABN AMRO (corporate.broking@nl.abnamro.com).',
  label_toRegister_callIntermediary:
    'Pour enregistrer vos votes, appelez votre intermédiaire.',
  label_ask_provideIdentification:
    "Il vous sera demandé de présenter votre numéro d'identification:    ",
  label_contactInfo: 'Informations de contact',
  label_telephoneNumber: 'Numéro de téléphone',
  label_deptPhoneNumber: 'Numéro de téléphone',
  lable_onlyNumbers_error: 'Please enter number of shares',
  label_createNewAR: 'Créez une nouvelle demande de présence',
  label_createNewVI: 'Créez une nouvelle instruction de vote',
  label_editVotingIns: 'Modifier une requête de vote',
  label_createPE: 'Create PE',
  label_bulkUploadQrf: 'Bulk QRF',
  label_dwt_notification_link: 'DWT notification link',
  label_recipient: 'Recipient',
  label_downloadQRFBulkTemplate: 'DWT QRF Bulk upload template',
  label_bulkUploadQRF: 'Bulk upload QRF claim',
  label_request_date_time: 'Request date/time',
  label_requested_new_end: 'Requested new end date/time',
  label_QRF_PE: 'QRF / PE',
  label_current_QRF_PE_End_date: 'Current QRF/PE End date',
  label_reason_for_extension_request: 'Reason for extension request',
  label_document_download: 'Document download',
  label_downloadclaimoverview: 'Download Claims Overview',
  label_cc: 'cc',
  label_vebDefault: 'VEB[Défaut]',
  label_SelectSecurityClass: '--Sélectionner une catégorie d’action--',
  label_voteAttendEnd:
    'Vous ne pouvez plus voter ni vous inscrire pour cette assemblée',
  label_voteAttendStart:
    'Les votes et les inscriptions ne sont pas encore ouverts pour cette assemblée',
  confirm_proxyLinking: 'Are you sure you want to link this intermediary?',
  label_modalAttReqActionErrMsg:
    'You cannot accept/reject requests for this General Meeting as the intermediary deadline date is already crossed',
  max4000_characters_error: 'Maximum 4000 characters are allowed.',
  label_allSectionNotFilled:
    'Please fill all the sections with valid input and proceed.',
  label_terms_IN_line1:
    'We hereby confirm that we have verified the identity of the aforementioned shareholder and warrant his true identity.',
  label_terms_IN_line2:
    'We hereby confirm that the aforementioned shareholder is in possession of the mentioned securities of the Company at the record date. Should the Company not use a record date, then we hereby warrant that the aforementioned shareholder is in possession of the mentioned securities of the Company at the General Meeting date.',
  label_terms_IN_line3:
    "In addition, we hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned shareholder as registered for this General Meeting, with right of substitution, to represent, act and vote on the shareholder's behalf, in accordance with the shareholder's instructions,  at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Company, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.",
  label_terms_IN_line4:
    'Furthermore, we hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned shareholder in connection with this General Meeting and that we nor the aforementioned shareholder will submit voting instructions other than those being submitted via this portal on the Website.',
  label_scroll_toAccept:
    'Scroll naar beneden om de gebruiksvoorwaarden te lezen en te accepteren.',
  acceptTc_shRegistration:
    "Je reconnais avoir lu, compris et consenti aux conditions d'utilisation ainsi que d'avoir sauvegardé, imprimé ou de m'être envoyé une copie de celles-ci.",
  label_shRegistration_infoMsg:
    'Nous vous prions d’avoir l’amabilité d’indiquer les mêmes données personnelles que celles enregistrées chez votre établissement financier. ',
  label_shRegistration_infoMsg2:
    "Nous utiliserons uniquement les données personnelles que vous fournissez pour enregistrer vos votes concernant l'assemblée générale ou pour vous envoyer un certificat d'enregistrement. Pour plus d'informations sur la manière dont nous utilisons vos données, consultez notre ",
  label_shRegistration_infoMsg3: 'déclaration de confidentialité.',
  file_sizeMsg25MB: ' MB. Please upload a file less than or equal to 25 MB.',
  label_all: '--Tout--',
  contact_heading1: 'ABN AMRO Bank N.V. Corporate Broking',
  label_shareholderLogin: 'Accès actionnaire',
  intermediary_notAllowedToVote:
    'You are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.',
  intermediary_noPOAAvailableMessage:
    "There are no valid PoA's in place, so at this moment you are not allowed to process voting instructions. Please contact ABN AMRO Bank N.V.",
  intermediary_intermediaryPOAListCheckbox:
    'We hereby confirm that we only upload on behalf of the following Intemediaries, for which a valid power of attorney is available: ',
  intermediary_modalcheck1:
    'We hereby confirm that we have verified the identity of the aforementioned Securities Holder(s), and represent and warrant his/their true identity.',
  intermediary_modalcheck2:
    'We hereby confirm that the aforementioned Securities Holder(s) hold(s) the mentioned Securities of the Issuing Institution at the record date.',
  intermediary_VI_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to represent, act and vote on behalf of the Securities Holder(s), in accordance with the Securities Holder(s) instructions, at this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration and/or the proxy voting procedure for this General Meeting.',
  intermediary_modalcheck4:
    'We hereby confirm that no prior voting instructions have been submitted or will be submitted by the aforementioned Securities Holder(s) in connection with this General Meeting and that we nor the aforementioned Securities Holder(s) will submit voting instructions other than those being submitted via the Website.',
  intermediary_modalcheck5:
    'We hereby confirm that we have uploaded the individual voting instruction(s) for which we have submitted a total voting instruction (block vote)',
  intermediary_modalcheck6:
    'We hereby confirm that we will provide the registration certificate(s) to the Securities Holder(s) and instruct the Securities Holder(s) to bring the registration certificate(s) to the General Meeting. In case of the appointment of a Proxy Holder, we hereby confirm that we will provide the Proxy(s) to the Securities Holder(s) and instruct the Securities Holder(s) to print, manually sign and provide this Proxy to the Proxy Holder, who must bring this manually signed document to the General Meeting.',
  intermediary_AR_reject_modalcheck4:
    'We hereby confirm that we will instruct the Securities Holder(s), where applicable, to collect the manually signed document from the Proxy Holder(s).',
  intermediary_AR_modalcheck3:
    'We hereby confirm that we, as Intermediary, are entitled through a contractual relationship with the aforementioned Securities Holder(s), with right of substitution, to register the Securities Holder(s) for participation for this General Meeting. We will provide the documentation proving the chain of power of attorneys  to ABN AMRO, the Issuing Institution, the civil law notary or any other person who is entrusted with the registration procedure for this General Meeting.',
  intermediary_BulkUpload_int_checkbox5: 'We hereby appoint',
  intermediary_BulkUpload_int_checkbox5_2:
    'as Proxy Holder to represent the Securities Holder(s) listed in this bulk upload at the General Meeting of ',
  intermediary_BulkUpload_int_checkbox5_3: '(the "Company") on',
  intermediary_BulkUpload_int_checkbox5_4:
    " and to exercise the voting rights attached to the Securities listed in this bulk upload on the Securities Holder(s)' behalf in this General Meeting.",
  intDeadline_shdedline_Tooltip:
    'Please note that for the Belgium market this date is always the six calendar day before the date of the meeting.',
  label_proxy: 'Procuration',
  label_participation_by_proxy : 'Participation par procuration',
  label_vote_by_correspondence: 'Vote par correspondance',
  label_participation_method: 'Participation Method',
  label_select_participation_method: '--Select Participation method--',
  vi_intermediary_individual_disclaimer1: 'Par la présente, nous désignons ',
  vi_intermediary_disclaimer1: 'Par la présente, je/nous désigne/désignons ',
  vi_intermediary_disclaimer2: ' du ',
  vi_intermediary_disclaimer3:
    " comme Mandataire en vue de représenter le Porteur des Titres susmentionné à l'Assemblée Générale de ",
  vi_intermediary_disclaimer4: ' (la "Société") du ',
  vi_intermediary_disclaimer5: ' à ',
  vi_intermediary_disclaimer6: ' à ',
  vi_intermediary_disclaimer7:
    " et d'exercer les droits de vote attachés aux Titres susmentionnés lors de l'Assemblée Générale au nom du Porteur des Titres.",
  vi_intermediary_disclaimer_telenet0:
    'In case of absence of voting instructions given to the proxy holder with regard to the respective agenda items or if, for whatever reason, there is a lack of clarity with regard to the voting instructions or if there should be a vote on decisions to be taken by the general meeting during the meeting, subject to compliance with the company code, the proxy holder will always vote in favour of the proposed resolution, possibly amended.',
  vi_intermediary_disclaimer8:
    "Par la présente, je/nous donne/donnons au Mandataire les instructions dessus en vue de voter à l'Assemble Générale de la Société.",
  vi_vote_by_correspondence_disclaimer: 'Votre vote sera directement attribué à l’émetteur de l’assemblée et votre vote sera immédiatement divulgué à l’émetteur après la confirmation de l’admissibilité de votre intermédiaire.',
  vi_shareholder_disclaimer1: 'Par la présente, je/nous désigne/désignons ',
  vi_shareholder_disclaimer2: ' du ',
  vi_shareholder_disclaimer3:
    " comme Mandataire en vue de participer à l'Assemblée Générale de ",
  vi_shareholder_disclaimer4: ' (la "Société") du ',
  vi_shareholder_disclaimer5: ' à ',
  vi_shareholder_disclaimer6: ' à ',
  vi_shareholder_disclaimer7:
    " en mon/notre nom, de participer aux délibérations et d'exercer les droits de vote attachés aux Titres susmentionnés conformément aux instructions dessus en mon/notre nom, et de signer tous documents et/ou faire tous autres actes confirmant les votes exercées. ",
  vi_shareholder_disclaimer8:
    "Par la présente, je/nous donne/donnons au Mandataire les instructions suivantes en vue de voter en mon/notre nom à l'Assemble Générale de la Société:",
  vi_shareholder_disclaimer8_with_right_of_substitution:
    "I/we hereby give the Vote Collector (with a right of substitution) the following instructions mentioned above to vote in my/our name at the Company's General Meeting.",
  label_tou_registration: 'Mandataire',
  label_tou_registration_definitions:
    "la personne (physique ou morale) à laquelle l’Utilisateur a donné le pourvoir, via le Portail de Vote Par Procuration, respectivement le Portail d'Enregistrement pour exercer les droits de vote attachés aux Titres lors d'une Assemblée générale spécifique au nom du Porteur de Titres.",
  label_tou_registration1: 'Numéro Unique d’Identification',
  label_tou_registration_definitions1:
    "le numéro unique d’identification qui apparaît au Portail d'Enregistrement, respectivement au Portail de Vote Par Procuration et que l'Utilisateur doit fournir à l'Intermédiaire pour compléter l'Enregistrement, respectivement la Procuration;",
  'label_terms_regSection4.1':
    "Le Portail d'Enregistrement peut être utilisé pour s'enregistrer pour participation à une Assemblée Générale spécifique.",
  'label_terms_regSection4.2':
    'Afin de compléter l\'Enregistrement, l’Utilisateur doit ("\'Procédure de Signature") :',
  'label_terms_regSection4.3':
    "L’Utilisateur doit entrer honnêtement les données demandées dans les champs spécifiques au Portail d'Enregistrement du Site. À réception d’un courriel d’alerte envoyé par ABN AMRO, l’Intermédiaire entré par l’Utilisateur au Portail d'Enregistrement vérifie les données fournies par l’Utilisateur. Cet Intermédiaire signale à ABN AMRO toute donnée fournie incorrecte. Puis il en informe également l’Utilisateur qui peut modifier ces données incorrectes jusqu'à l'expiration du délai spécifié pendant lequel l'Utilisateur peut utiliser le Portail d'Enregistrement pour une Assemblée Générale spécifique. L’Intermédiaire vérifie les données modifiées et, si toutes les données sont correctes, ABN AMRO le notifie à l'Utilisateur. Le défaut de modification de toute donnée incorrecte dans le délai pendant lequel l'Utilisateur peut utiliser le Portail d'Enregistrement pour une Assemblée Générale spécifique a pour conséquence que l'Enregistrement ne peut pas être complété et qu’il sera considéré comme n'ayant pas eu lieu.",
  'label_terms_regSection4.21':
    "fournir le Numéro Unique d’Identification apparaissant dans le Portail d'Enregistrement à l’Intermédiaire, qui vérifie ce numéro et l’identité de l’Utilisateur;",
  'label_terms_regSection4.22':
    'lire et reconfirmer son accord aux présentes Conditions d’Utilisation; et',
  'label_terms_regSection4.23': 'confirmer son enregistrement.',
  'label_terms_regSection4.24':
    "En complétant la Procédure de Signature exposée ci-dessus, l’Utilisateur reconnaît et convient que l'Enregistrement a été signée valablement par lui (également dans le cas où un tiers a signé l'Enregistrement avec ou sans sa permission) au moyen d’une signature électronique. L’Utilisateur reconnaît et convient que la signature électronique utilisée au Portail d'Enregistrement, plus particulièrement la Procédure de Signature, aura un effet juridique équivalent à celui d’une signature manuscrite.",
  'label_terms_proxySection4.4new':
    "Le Mandataire bénéficie des mêmes droits à l'Assemblée Générale que le Porteur de Titres ainsi représenté et, en particulier, du droit de prendre la parole, de poser des questions et d'exercer le droit de vote.",
  'label_terms_regSection4.4.1new':
    'fournir le Numéro Unique d’Identification apparaissant dans le Portail de Vote Par Procuration à l’Intermédiaire, qui vérifie ce Numéro et l’identité de l’Utilisateur ;',
  'label_terms_regSection4.4.2new':
    'lire et reconfirmer son accord aux présentes Conditions d’utilisation ; et',
  'label_terms_regSection4.4.3new': 'confirmer son enregistrement.',
  'label_terms_proxySection4.6':
    'La Procuration ne constitue pas une demande de procuration.',
  'label_terms_proxySection4.7':
    'La Procuration donnée pour une Assemblée Générale spécifique vaut pour les Assemblées Générales successives convoquées avec le même ordre du jour.',
  'label_terms_proxySection4.8':
    "Les Procurations notifiées antérieurement à la publication d'un ordre du jour complété, selon le cas, restent valables pour les sujets à traiter inscrits à l'ordre du jour qu'elles couvrent. Le Mandataire n'est pas autorisé à voter sur les sujets à traiter nouveaux inscrits à l'ordre du jour son instructions.",
  'label_terms_generalProvisions11.5.2new':
    'A Consumer domiciled in Belgium may bring proceedings in relation these Terms of Use and/or the use of the Website before the competent court in the Netherlands, or before the competent court in Belgium. In the latter case, the applicable laws shall be the laws of Belgium.',
  'label_terms_generalProvisions11.5.3new':
    'Proceedings in relation to these Terms of Use and/or the use of the Website may be brought against a Consumer domiciled in Belgium only before the court in Belgium. In this case, the applicable laws shall be the laws of Belgium.',
  'label_terms_definitions1.5header': 'Conditions d’Utilisation',
  'label_terms_definitions1.6header': 'Émetteur',
  locale_tou_and: 'et',
  label_tou_securitiesHolder: 'Porteur De Titres',
  label_tou_termsUse: 'PT Procédure De Signature',
  label_tou_uniqueIdentificationNumber: 'Titres',
  label_tou_user: 'Vote Collector',
  label_tou_header4: 'Enregistrement',
  'label_tou_header4.1': 'ENREGISTREMENT PAR LE PORTEUR DE TITRES',
  'label_terms_regSHSection4.1.1':
    "Par le Portail d'Enregistrement un Porteur de Titres peut s'enregistrer pour participation à une Assemblée Générale spécifique.",
  'label_terms_regSHSection4.1.2':
    'Afin de compléter l\'Enregistrement (ou la révocation de celui-ci), le Porteur de Titres doit ("PT Procédure de Signature"):',
  'label_terms_regSHSection4.1.21':
    "fournir le Numéro Unique d’Identification apparaissant dans le Portail d'Enregistrement à l’Intermédiaire, qui vérifie ce numéro, l’identité et la qualité du Porteur de Titres;",
  'label_terms_regSHSection4.1.22':
    'lire et reconfirmer son accord aux présentes Conditions d’Utilisation; et',
  'label_terms_regSHSection4.1.23': 'confirmer son enregistrement.',
  'label_terms_regSHSection4.1.3':
    "En complétant la PT Procédure de Signature exposée ci-dessus, le Porteur de Titres reconnaît et convient que l'Enregistrement (ou la révocation de celui-ci) a été signée valablement par lui (également dans le cas où un tiers a signé l'Enregistrement (ou la révocation de celui-ci) avec ou sans sa permission) au moyen d’une signature électronique. Le Porteur de Titres reconnaît et convient que la signature électronique utilisée au Portail d'Enregistrement, plus particulièrement la PT Procédure de Signature, aura un effet juridique équivalent à celui d’une signature manuscrite.",
  'label_terms_regSHSection4.1.4':
    "A la fin de l'Enregistrement, le Porteur de Titres reçoit un certificat d'Enregistrement qu'il/elle doit emporter à l'Assemblée Générale.",
  'label_terms_regSHSection4.1.5':
    "Le Porteur de Titres doit entrer honnêtement les données demandées dans les champs spécifiques au Portail d'Enregistrement du Site. À réception d’un courriel d’alerte envoyé par ABN AMRO, l’Intermédiaire entré par le Porteur de Titres au Portail d'Enregistrement vérifie les données fournies par le Porteur de Titres. Cet Intermédiaire signale à ABN AMRO toute donnée fournie incorrecte. Puis il en informe également le Porteur de Titres qui peut modifier ces données incorrectes jusqu'à l'expiration du délai spécifié pendant lequel le Porteur de Titres peut utiliser le Portail d'Enregistrement pour une Assemblée Générale spécifique. L’Intermédiaire vérifie les données modifiées et, si toutes les données sont correctes, ABN AMRO le notifie à le Porteur de Titres. Le défaut de modification de toute donnée incorrecte dans le délai pendant lequel le Porteur de Titres peut utiliser le Portail d'Enregistrement pour une Assemblée Générale spécifique a pour conséquence que l'Enregistrement ne peut pas être complété et qu’il sera considéré comme n'ayant pas eu lieu.",
  'label_tou_header4.2': "ENREGISTREMENT PAR L'INTERMEDIAIRE",
  'label_terms_regSHSection4.2.1':
    "Par le Portail d'Enregistrement un Intermédiaire, agissant au nom et pour le compte d'un Porteur de Titres, peut enregistrer un Porteur de Titres pour participation à une Assemblée Générale spécifique.",
  'label_terms_regSHSection4.2.2':
    'Afin de compléter l\'Enregistrement (ou la révocation de celui-ci), l\'Intermédiaire doit ("IM Procédure de Signature"):',
  'label_terms_regSHSection4.2.21':
    'vérifier et confirmer l’identité et la qualité du Porteur de Titres;',
  'label_terms_regSHSection4.2.22':
    'lire et reconfirmer son accord aux présentes Conditions d’Utilisation; et',
  'label_terms_regSHSection4.2.23': "confirmer l'enregistrement.",
  'label_terms_regSHSection4.2.3':
    "En complétant la IM Procédure de Signature exposée ci-dessus, l'Intermédiaire reconnaît et convient que l'Enregistrement (ou la révocation de celui-ci) a été signée valablement par lui (également dans le cas où un tiers a signé l'Enregistrement (ou la révocation de celui-ci) avec ou sans sa permission) au moyen d’une signature électronique. L'Intermédiaire reconnaît et convient que la signature électronique utilisée au Portail d'Enregistrement, plus particulièrement la IM Procédure de Signature, aura un effet juridique équivalent à celui d’une signature manuscrite.",
  'label_terms_regSHSection4.2.4':
    "A la fin de l'Enregistrement, l'Intermédiaire reçoit un certificat d'enregistrement pour le compte du/des Porteur(s) des Titres qu'il a enregistré. L'Intermédiaire doit fournir ce certificat d'enregistrement à/aux Porteur(s) des Titres, qui doit/doivent emporter ce certificat à l'Assemblée Générale.",
  'label_terms_regSHSection4.2.5':
    "L'Intermédiaire doit entrer honnêtement les données demandées dans les champs spécifiques au Portail d'Enregistrement du Site.",
  'label_terms_regSHSection4.2.6':
    "L'Intermédiaire qui utilises le Portail d'Enregistrement, agissant au nom et pour le compte d'un Porteur de Titres, (i) le fera uniquement sur la base de et dans le cadre d’une procuration juridiquement valable, (ii) se conformera à toute obligation de cette procuration ou relative à cette procuration, et (iii) fournira à première demande la documentation prouvant la compétence pour agir au nom et pour le compte du Porteur de Titres à ABN AMRO, l’Émetteur, le notaire ou à toute autre personne chargée de l'enregistrement et/ou la procédure de vote par procuration pour une Assemblée Générale spécifique.",

  label_downloadPDF_voting: 'Voting instruction per registration PDF',
  label_downloadExcel_voting: 'Voting instruction per registration Excel',
  label_accountLocked:
    "Le compte de l'utilisateur est verrouillé. Veuillez réinitialiser votre mot de passe.",
  label_technicalError: 'Technical Error, Please try again later',
  label_sessionExpired: 'User Session has expired, Please Login to continue',
  label_selectVC: '--Séléctionner collecteur de vote--',
  label_confirmVotes_prevGm:
    'Votes confirmés d’anciennes assemblées générales.',
  label_no_ar_available: 'Aucune inscription disponible',
  label_no_vi_available: 'Aucune instruction de vote disponible',
  label_approved_RC: 'Approuvé, demande d’annulation.',
  label_cancellation_requested: 'Demande d’annulation.',
  extnbtn3: 'Cancelled',
  label_noAR: 'No attendance requests available in this status',
  label_noVI: 'No voting instructions available in this status',
  label_no_confVI_available: 'Geen bevestigde steminstructies aanwezig',
  label_votingExists_error:
    "Instructions de vote déjà disponibles pour ce catégorie de titre et intermédiaire. Voir 'Mes votes /mes inscriptions'",
  label_cancelReg_info:
    'Les inscriptions suivantes seront annulées lorsque les instructions de vote auront été approuvées par votre intermédiaire',
  label_cancelVoting_info:
    "Les instructions de vote suivantes seront annulées une fois que cette demande d'inscription aura été confirmée par votre intermédiaire",
  label_noVCavailable: 'No vote collector available',
  label_noIntermediaryAvailable: 'No intermediary available',
  label_notVotable: 'Non votable',
  label_notVotableAgenda:
    "Cette assemblée générale n'a pas de point à l'ordre du jour soumis au vote",
  modal_header_selectLang: 'Veuillez sélectionner la langue à télécharger',
  label_accessDate: "date d'accès",
  mail_sentToIssuer_text1: 'Mail has been sent already on ',
  mail_sentToIssuer_text2: '. Please check before sending again.',
  label_updatePwd: 'Mise à jour du mot de passe',
  modal_areYousure_changePwd:
    'Êtes-vous sûr de vouloir changer le mot de passe?',
  label_update_accountDetails: 'Mise à jour des détails',
  modal_areYouSure_updateDetails:
    'Êtes-vous sûr de vouloir modifier vos coordonnées?',
  label_noGMMessage: 'No general meeting available',
  label_proxyTitle: 'Proxy title',
  label_proxyCountry: 'Proxy country',
  label_proxyCity: 'Proxy city',
  label_noDataGMIssuerMessage: 'No general meeting available for issuer',
  label_utc: 'UTC',
  label_uploadBOFile: 'Upload BO file',
  label_uploadBOFileError:
    'Uploading a BO file in ".xlsx" is mandatory if the user type is NOMI or TRUS',
  label_checkShareholder: 'Check for shareholder',
  label_edit_IN_AR_BOFile: 'Modifier la demande de présence',
  label_total_voting_instrunction: 'Total voting instructions',
  label_voting_inst_per_reg: 'Voting instruction per registration',
  label_pdf: 'Pdf',
  label_excel: 'Excel',
  label_cancelReq: 'Annuler la demande',
  label_reqCancellation: 'Demande d`annulation',
  label_dnldRegCerti: 'Télécharger le certificat d`inscription',
  label_addIntSucessMsg: 'Verzoek intermediair is aangemaakt',
  label_pwdSuccessMsg: 'Le mot de passe a été mis à jour',
  lable_accountUpdateSuccessMsg: 'Le compte utilisateur a été mis à jour',
  label_noShareclassAvailable: 'No shareclass available',
  label_selectRegCertLang: 'Select registration certificate language',
  label_sendRegTicketToShareholder:
    'Send registration tickets to shareholders based on provided mail address after upload in ',
  label_pn: 'Numéro de téléphone',
  label_coc: 'Chambre du Commerce',
  label_vat: 'numéro de TVA',
  label_contact_details_footer: 'Contact',
  label_noSHGMFound:
    "Il n'y a pas de prochaines assemblées générales disponibles",
  label_voting_disabled: 'Le vote est désactivé pour cette réunion.',
  label_attendance_disabled:
    'La participation est désactivée pour cette réunion.',
  checkPattern_Pwd_length:
    'Le mot de passe doit comporter au moins 8 caractères',
  label_DobInvalid: 'La date de naissance est obligatoire (jj-mm-aaaa)',
  label_dobFutureError: 'La date de naissance ne peut pas être dans le futur',
  label_dateFutureError: 'La date peut ne pas être dans le futur',
  label_dateInvalid: "La date doit être au format 'jj-MM-aaaa'",
  label_voteNotPossible: 'Il n`est pas possible de voter pour cette assemblée',
  label_attendNotPossible:
    'Il n`est pas possible de s`inscrire pour cette assemblée',
  label_attendNotEnabled1:
    'It is unfortunately (no longer) possible for shareholders to register for attendance of the meeting in person. You can contact',
  label_attendNotEnabled2:
    'Proxy/ voting instructions can be granted via the button ‘give voting instructions’. When virtual attendance has been made available, shareholders can register for virtual attendance via the button ‘attend meeting virtually',
  label_attendDisabled1_intermediary:
    'It is unfortunately not possible for shareholders to attend the meeting in person. Therefor create new registration and also bulk upload is not allowed. Your clients (shareholders) should contact the company ',
  label_attendDisabled2_intermediary:
    ' for approval to attend the meeting in person.',
  label_voteAttendNotAllowed:
    'Vous ne pouvez plus voter ni vous inscrire pour cette assemblée',
  label_voteAttendNotAllowedAccessDate:
    "Vote / Participation sera activé après la date d'accès des actionnaires",
  label_shareholderExists:
    "Cette adresse courriel existe déjà. Vous pouvez vous connecter directement ou demander un nouveau mot de passe si vous l'avez oublié.",
  label_oldPassIncorrect: 'Ancien mot de passe est incorrect',
  label_existLastPassList:
    'Ce mot de passe existe dans la liste des 12 derniers mots de passe',
  label_passLengthIncorrect: 'La longueur du mot de passe est incorrecte',
  label_dataNotFound: 'Données non trouvées pour l’entrée donnée',
  label_passNotMatch: 'Le mot de passe ne correspond pas',
  label_accessDenied: 'accès refusé',
  label_globalError_1:
    'Désolé, une erreur s’est produite pour l’opération demandée. D’autres opérations peuvent fonctionner, mais ne peuvent pas être garanties.',
  label_globalError_2:
    'Pour une communication directe avec le support technique, envoyez un e-mail à corporate.broking@nl.abnamro.com avec des détails sur l’action que vous avez effectuée et nous vous contacterons.',
  label_error: 'Erreur!',
  label_newVote: 'Nouvelle instruction',
  label_newRegistration: 'Nouvelle inscription',
  label_createRTS: 'Create new RTS request',
  label_editRTS: 'Edit RTS request',
  label_continue: 'Continue',
  label_conf_votes: 'Votes confirmés',
  lable_AR_proxy_error:
    'La demande de participation (pour participer à vous-même) est déjà disponible pour cette Assemblée',
  lable_AR_shares_error:
    'Vous ne pouvez pas voter ou vous inscrire à cette assemblée car avec le nombre de titres que vous avez entré, vous dépassez le nombre total de titres en circulation pour cette Catégorie de titres.',
  label_edit_extn_request: 'Edit Extension Request',
  label_shGMNotFound:
    'Actuellement, il n’y a pas de réunions générales disponibles ',
  label_forYourSearchTerm: 'qui correspond à votre recherche',
  label_defaulProxyForbulkUpload:
    "Add 'Iedere vertegenwoordiger van de VEB' as proxy for all attendance requests in this upload file",
  label_request_date: 'Request date',
  label_dateandtime: 'Date and time',
  label_exclamation: '!',
  label_noDWTavailable: 'There are no DWT events available',
  label_noDataGMIssuerMessage_DW: 'No DWT events available for issuer',
  label_portalInfoExample:
    'This portal is still under construction and the information shown is an example',
  label_error_nameInvalid:
    'Le nom est invalide. Au moins 2 caractères valides sont attendus',
  label_thereAreNo: ' There are no',
  label_eventsAtMoment: 'tax reclaim events at this moment',
  label_draft: 'draft',
  label_pending: 'pending',
  label_toBePublished: 'to be published',
  label_live: 'live',
  label_completed: 'completed',
  label_cancelled: 'cancelled',
  label_documentLessThan25mb: 'Document should be less than 25mb',
  label_iWouldAttend:
    "Je voudrais m'inscrire pour être présent lors d'Assemblées générales.",
  label_iWouldVote:
    "Je voudrais soumettre des instructions de vote pour l'Assemblées générales",
  label_checkSCMsg:
    'Add check to prevent register or vote to wrong share class',
  label_sendEmailEuro: 'Send email to Euroclear',
  label_downloadEuroclearReport: 'Download Euroclear report',
  label_agentWS: 'Agent workstation',
  label_sendVIreg_toEuroclear:
    'Option to send registrations and voting instructions to Euroclear',
  label_proceed: 'Continuer',
  label_dear: 'Dear ',
  label_noShareholderDetails: 'No shareholder details found',
  label_euroclear_mesage: 'Mail has been sent already on',
  label_plsCheck_mesage: 'Please check before sending again.',
  label_reSendEmailEuro:
    'Mail has been already sent. Do you want to send it again?',
  label_subject_required: 'Subject is required.',
  label_emailboday_required: 'Email body is required.',
  sendVIreg_toEuroclear_Tooltip: 'This option is mandatory for Shell',
  label_download_intermediary_instructions: 'Download accepted instructions',
  label_nwkError_1: 'Please validate your internet connectivity and check:',
  label_nwkError_2: '-- Checking the network cables, modem, and router',
  label_nwkError_3: '-- Reconnecting to Wi-Fi',
  label_nwkError_4:
    '-- Refresh the browser (press F5) once the connectivity issue is resolved',
  label_network_error_lost: 'Internet connection lost',
  label_network_error_1:
    'The page could not be loaded as there is or was an issue with your internet connection.',
  label_network_error_2:
    'Please check your connection and refresh the browser (press F5) once the connection issue is resolved.',
  label_noVCavailable_forVote:
    'This general meeting and shareclass does not have any vote collector',
  label_aegon_issuer: 'The AGM will be webcast on Aegon’s website',
  label_instruction_not_available:
    'There are no accepted instructions to download',
  vi_intermediary_disclaimer_telenet1:
    'I acknowledge that the president of the board of directors of the Company, currently IDw Consult BV, with as permanent representative Mr. Bert De Graeve, is a director of Telenet Group Holding NV. In his capacity of director, he has a potential conflict of interest as set out in article 7:143, §4 of the Belgian Code of Companies and Associations. In case of a potential conflict of interest he will only vote in execution of this proxy in accordance with the specific voting instructions set out in this proxy. In absence of a specific voting instruction, he will be supposed to have received the specific voting instruction to vote in favor. \n',
  vi_intermediary_disclaimer_telenet2: '',
  lable_numberOfShares_error: 'Invalid number of shares',
  lable_5posOnly: 'Les initiales peuvent être au maximum de 5 positions',
  label_votingAdvise: 'Voting advise',
  label_shareholder_link: 'www.abnamro.com/shareholder',
  label_intermediary_link: 'www.abnamro.com/intermediary',
  label_proceedToLogout: 'Procéder à ',
  label_asACustomerOf: 'En tant que client de ',
  label_voteViaIntermediary:
    ' vous ne pouvez vous inscrire à la réunion via le site ',
  label_alex_binkBank_warning:
    ' vous ne pouvez voter que pour la réunion via le site',
  label_reloadCancelError:
    'Please choose different meeting or a different shareclass to proceed.',
  label_teleNumber: 'Numéro de téléphone du ministère',
  label_addresseeEmail: 'Adresse e-mail',
  lable_contactDetails: 'Détails du contact',
  pwdChanged_successMsg_proceed:
    'Password changed successfully. Redirecting to login page...',
  pwdExpiry_msg:
    'Your password is expired, please change your password. Be aware that you cannot use the last 12 used passwords.',
  label_viewVotingInstruction: 'View voting instruction',
  label_login_failed: 'Login redirect failed ',
  label_noVIAvailable: 'No voting instruction available for ',
  label_of: 'of',
  label_pwd_requirement: 'Please check the password requirements',
  label_one_email:
    "Please ensure that only one valid email id is entered in the 'To' address field",
  lable_mobile_number: 'Téléphone portable',
  label_mobile_number_reenter: 'Ré-entrer le numéro de téléphone',
  lable_mobile_number_guidelines:
    "Merci de sélectionner l'indicatif du pays dans la liste déroulante et d'entrer le numéro de portable en suivant les indications suivantes:",
  lable_mobile_number_numeric: '- Le numéro de télephone doit être numérique.',
  lable_mobile_number_length: '- La longueur maximale est de 15 chiffres.',
  label_mobile_number_numeric_error:
    'Le numéro de télephone doit être numérique.',
  label_phone_number_exists_error: 'Le numéro de téléphone est déjà utilisé.',
  label_country_code_error: 'Please select the country code.',
  label_virtual_participation: 'Assister à une réunion virtuellement',
  label_virtual_participation_no_voting:
    'Virtual Attendance, PROXY VOTING ONLY',
  lable_Reject_reason: 'Rejected reason',
  label_noVirtualParticipation:
    'There are no Virtual Participation Request available',
  label_footerTermOfUse: 'Footer Term of Use',
  label_attend_virtualy: 'Participation virtuelle',
  label_vote_sh: 'Vote',
  label_attend_sh: 'Attend',
  label_virtualPartNotAllowedAccessDate:
    'Virtual participation will be enabled after shareholder access date',
  label_register_virtualParticipation_step_counter_1:
    'Comptes et participations',
  label_register_virtualParticipation_step_counter_2:
    'Téléphone termes et conditions',
  label_register_virtualParticipation_step_counter_3: 'Code de vérification',
  label_register_virtualParticipation:
    'Enregistrement pour la participation virtuelle',
  label_register_virtualParticipation_verify_phone:
    'Vérification du numéro de téléphone',
  label_register_virtualParticipation_acknowledge: 'Reconnaître et accepter',
  label_register_virtualParticipation_enter_code: 'Entrer le code',
  label_register_virtualParticipation_success:
    ' Vous vous êtes enregistré avec succès à la participation virtuelle',
  label_register_virtualParticipation_success_message:
    'Maintenant que vous  avez demandé à participer virtuellement, votre intermédiaire devra fournir son approbation.',
  label_register_virtualParticipation_please_confirm:
    'Please confirm your account number and telephone number to your intermediary',
  label_register_virtualParticipation_question: 'Question',
  label_register_virtualParticipation_question_message:
    "En cas, d'autre question, n'hésitez pas à contacter ABN AMRO.",
  label_verfication_code_sent:
    'Un nouveau code de vérification a été envoyé à votre téléphone portable.',
  label_verifying_code: 'Vérification du code...',
  label_verfication_sending_new_code: 'Envoi d\'un nouveau code',
  label_otp_resend_message_part1: 'Le bouton Envoyer de nouveau sera activé dans  ',
  label_otp_resend_message_part2: ' secondes, veuillez attendre un code PIN unique (OTP).',
  label_send_again: 'Envoyer de nouveau',
  label_code: 'Code',
  label_back: 'Retour',
  label_verify: 'Vérifier',
  label_contact_intermediary: 'merci de contacter votre intermédiare',
  label_join_meeting: "Rejoindre l'assemblée",
  label_to_my_events: 'Vers mes événements',
  label_account: 'Compte',
  label_terms_vp1:
    'I/We herby confirm that I/We have read, understood and consented the',
  label_terms_vp2:
    'as well as having saved, printed or sent myself/ourselves a copy thereof.',
  label_contact: 'Contact',
  label_helpdesk: 'Aide',
  label_shares: 'Actions',
  label_SAN_error: 'Merci de renseigner un compte titres valide',
  label_SAN_exists_error:
    'Securities account number already exists for this meeting',
  lable_TermsAndConditions: 'Termes et conditions',
  lable_phoneNumberVerification:
    "Le numéro de téléphone ne peut pas être changé une fois vérifié et doit être utilisé jusqu'à la fin de l'assemblée..",
  label_virtualParticipationRequests: 'Virtual participation requests',
  label_no_virtualParticipation: 'Pas de participation virtuelle disponible',
  lable_mobileNumMismatch: 'Non concordance entre les numéros de téléphone',
  label_phoneNumber_exists_meeting_error:
    " Vous vous êtes déjà enregistré pour l'assemblée avec un numéro de téléphone différent, merci de corriger et de vérifier les enregistrements dans la section adéquate.",
  label_numeric_value_error: "Merci d'entrer une valeur numérique",
  label_meeting_details: " Détail de l'assemblée",
  label_join: 'participer',
  label_join_meeting_enable_hover_text:
    "Cliquer pour participer à l'assemblée.",
  label_join_meeting_disable_hover_text1:
    "Vous pouvez rejoindre l'assemblée entre",
  label_join_meeting_disable_hover_text2: 'et minuit le',
  label_virtual_participation_leave_cbp_warning:
    "Vous êtes sur le point de quitter le portail de vote en ligne d’ABN AMRO et vous allez être redirigé.e vers l'application/le site pour une participation virtuelle. Cette application/site n'est pas gérée par ABN AMRO et, par conséquent, ABN AMRO n'est pas responsable de son contenu, ni de sa disponibilité ou de sa fonctionnalité. Si vous avez des questions ou des soucis sur l’application/le site de participation virtuelle, veuillez appeler au +31 20 628 6070.",
  label_button_accept_and_proceed: 'Accepter et continuer',
  label_helpDesk_Number: '+31 20 628 6070',
  label_Important_Information: 'Information importante',
  label_cbp_email: 'corporate.broking@nl.abnamro.com',
  label_dual_factor_authentication_success:
    "Vous avez été redirigé avec succès vers l'application/le site pour participation virtuelle",
  label_authentication_generic_message:
    "Pardon, nous sommes dans l'incapacité de gérer votre requête. Merci de contacter le support.",
  label_2FA_OTP_fail:
    'Il y a un échec dans la vérification du code, mercide réessayer',
  label_Invalid_OTP_msg:
    "OTP invalide/expirée, Merci d'utiliser la possibilité de réenvoi pour créer une nouvelle OTP.",
  CBP_ERR_573: 'Le compte titre existe déjà pour cette assemblée',
  label_like_to_attend_virtually:
    "Je voudrais participer l'assemblée virtuelle",
  label_virtual_Meeting_Id_mandatory_msg:
    'For virtual participation, meeting id is mandatory',
  label_virtual_participation_provider_required_msg: 'A provider is required',
  label_download_registration_list: 'Download registration list',
  label_vp_registration_risk:
    "Vous êtes sur le point de confirmer votre souhait de participer virtuellement à l’Assemblée générale.\n\nSi vous souhaitez continuer, veuillez reconnaître et accepter les risques inhérents à l'admission et à la participation virtuelles à l’Assemblée générale en cochant la case correspondante ci-dessous, et en cliquant sur le bouton «∘Accepter et continuer∘» pour accéder à la Section de participation virtuelle.\n\nLes risques liés à l'admission et à la participation virtuelles à l’Assemblée générale comprennent, entre autres : toute perturbation dans l’équipement, logiciel, infrastructure, serveurs, connexion internet ou téléphonique, appareil vidéo ou de transmission de la voix servant à la réunion virtuelle, que cette perturbation soit ou non provoquée par un incident de sécurité informatique (ce qui comprend un accès non autorisé, le piratage, une attaque par déni de service, une perturbation ou intrusion résultant du vol ou de l’interception illicite d’un mot de passe ou d’un code d’accès au réseau et toute autre forme de cyberattaque) ou par tout type de défaillance mécanique comme une coupure de courant au cours de l’Assemblée générale, ainsi que tous problèmes ou interruptions techniques imprévisibles, qu’ils soient relatifs au site en général, à la Section de participation virtuelle ou à l’équipement/la connexion utilisés par vous, le Détenteur de titres.\n\nEn cochant les cases ci-dessous, vous confirmez:\n",
  label_vp_registration_risk_item1:
    'que vous reconnaissez et acceptez les risques décrits ci-dessus, et que vous avez lu et compris nos conditions applicables à l’inscription et à l’utilisation de la Section de participation virtuelle et que vous les acceptez.',
  label_vp_registration_risk_item2:
    "que vous êtes autorisés d'accepter ces risques soit en étant l'actionnaire soit en étant en droit de le représenter.",
  label_vp_registration_risk_item3:
    'que vous reconnaissez avoir lu, compris et accepté la Politique concernant vote hybride/virtuelle par les Actionnaires déclarée applicable par l’Émetteur, et que vous trouvez ici: ',
  label_vp_registration_risk_item3_no_link:
    "vous reconnaissez, le cas échéant, avoir lu, compris et accepté la politique relative à la réunion/participation hybride/virtuelle et la déclaration de confidentialité déclarée applicable par l'émetteur.",
  label_vp_registration_risk_document:
    'Les Conditions d’utilisation du Site et de la Section de participation virtuelle en particulier s’appliquent. Vous les trouverez ici: ',
  label_policy_issuing_institution: 'Émmeteur de Politique',
  label_virtual_Meeting_date_mandatory_msg: 'Enable join by date is required',
  label_Enable_join_date_error_msg:
    'Enable join by date should be greater than the shareholder access date',
  label_Enable_join_by: 'Enable join by',
  label_voting_options: 'Option de vote',
  label_record_date_required: 'Record date is required',
  label_record_date_format:
    'Record date format should be like \n 01-April-2017\n',
  label_record_date_before_GM:
    'Record date should be before general meeting date.',
  label_record_date_after_convocation:
    'Record date should be after convocation date.',
  label_publication_date_required: 'publication date is required.',
  label_publication_date_format:
    'publication date format should be like \n 01-April-2017\n.',
  label_publication_date_before_GM:
    'publication date should be before general meeting date.',
  label_publication_date_after_convocation:
    'publication date should be after convocation date.',
  label_gmProductType: 'GM',
  virtual_participation_allowed_tooltip:
    'Virtual participation can only be disabled when no pending or approved virtual participation requests exist for the meeting',
  registered_share_help_text:
    "Please select the share type for you which you would like to participate. If you are a registered holder in the company's share register, please click on registered shares.",
  label_intermediaryHelpText:
    "L'intermédiaire est l'institution où vous détenez votre compte tittres",
  label_approved_registrations: 'Approved registrations',
  label_approved_securities: 'Approved securities',
  label_pending_registrations: 'Pending registrations',
  label_total_pending_registrations: 'Total pending registrations',
  label_overview_of_live_general_meeting: 'Overview of live general meeting',
  label_modal_header_send_preview: 'Send preview',
  label_modal_confirm_template_updated: 'Template updated',
  label_modal_confirm_preview_sent: 'Preview email is sent',
  label_modal_failed_sent_failed: 'Sending the preview failed',
  label_modal_preview_sending: 'Sending preview...',
  CBP_ERR_604: 'reCAPTCHA vérification a échoué',
  CBP_ERR_227:
    "Cette adresse courriel existe déjà. Vous pouvez vous connecter directement ou demander un nouveau mot de passe si vous l'avez oublié.",
  CBP_ERR_572: 'Le numéro de téléphone est déjà utilisé.',
  label_status_LIVE: 'Live',
  label_status_PENDING: 'Pending',
  label_enterNumberOfSecurities: "Introduire nombre d'actions",
  vi_disclaimer_greenyard1:
    "In case you provide a proxy for the extraordinary general shareholders' meeting, the completed and signed proxy in original must either be sent by ordinary post to the Company at the following address:",
  vi_disclaimer_greenyard2:
    'Greenyard NV, attn. Ms. Fran Ooms, Strijbroek 10, 2860 Sint-Katelijne-Waver (Belgium), or be lodged at the extraordinary general shareholders’ meeting by the person who has been granted the proxy. If the Company has not received the original proxy before the start of the extraordinary general shareholders’ meeting then the proxy is not valid.',
  label_more_info: "Plus d'informations",
  toolTip_doNotPublish:
    'When selected the shareclass will not be published to the market and to normal shareholders. (When all shareclasses of a meeting are selected, the entire meeting itself is invisible to the market and shareholders)',
  label_sh_different_custodian_message: `Si vous avez des droits de vote auprès d'une autre banque dépositaire, <a href="mailto:${GM_CONTACT_EMAIL}">veuillez nous contacter.</a>`,
  label_updateOutstandingShares: 'Update Outstanding shares',
  label_updateOutstandingSharesConfirmation:
    'Are you sure you want to update the Total Outstanding Shares?',
  label_copyOutstandingSharesCsd: 'Copy Outstanding shares Csd',
  label_copyOutstandingSharesCsdConfirmation:
    'Are you sure you want to copy the Total Outstanding Shares via CSD to the Total Outstanding Shares?',
  label_voteExecutionConfirmation: 'Vote Execution Confirmation',
  label_vecStarted:
    'Meeting Vote Execution Confirmation is in progress. You will be informed by e-mail when finished.',
  label_vecNoVotingInstructions: 'No voting instructions to confirm',
  label_updateInstruction: 'Update Instruction',
  label_updateInstructionConfirmation:
    'Are you sure you want to update this instruction?',
  label_cancelInstruction: 'Cancel Instruction',
  label_cancelInstructionConfirmation:
    'Are you sure you want to cancel this instruction, this action cannot be undone.',
  label_updateShareplanHolder: 'Update Shareplan Holder',
  label_updateShareplanHolderConfirmation:
    'Updating the number of shares will also update the number of shares for a potential registration for this Shareplan Holder.',
  label_shareholderGmCategory0: 'Assemblées avec options de vote',
  label_shareholderGmCategory1: 'Assemblées avec date limite dans le passé',
  label_shareholderGmCategory2: 'Assemblées avec date d\'accès dans le futur',
};
